<template>
  <div :id="id" class="relative">
    <div
      ref="activator"
      class="peer flex h-[22px] w-max max-w-xs flex-row items-center justify-around gap-1 rounded-lg px-3 py-1 xs:h-8 xs:py-2 sm:py-4"
      :class="[
        disabled
          ? 'bg-secondary-05 text-black-40'
          : active
          ? 'bg-secondary-80 text-black-80'
          : 'bg-secondary-20 text-black-80',
      ]"
      @mouseover="checkActivatorPosition"
    >
      <UiIcon v-if="leftIcon" :name="leftIcon" size="xs"></UiIcon>
      <small class="truncate">
        <slot name="content" />
      </small>
      <UiIcon
        v-if="rightIcon"
        size="xs"
        :name="rightIcon"
        :class="{ 'cursor-pointer': iconClickable }"
        @click="$emit('icon-clicked')"
      ></UiIcon>
    </div>
    <div
      v-if="showTooltip"
      class="tooltip text-body-2 invisible absolute z-50 inline-block w-max min-w-[150px] max-w-[300px] whitespace-normal rounded-lg bg-black-100 px-3 py-2 font-medium text-white opacity-0 shadow-sm transition-all duration-200 after:left-[50%] peer-hover:visible peer-hover:opacity-100"
      :class="hasSpace ? 'top-8 xs:top-12' : 'tooltip-top -top-9 xs:-top-12'"
    >
      <slot name="tooltip"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
defineEmits(['icon-clicked'])

type Props = {
  id: string
  disabled?: boolean
  active?: boolean
  leftIcon?: string
  rightIcon?: string
  showTooltip?: boolean
  iconClickable?: boolean
}
withDefaults(defineProps<Props>(), {
  modelValue: '',
  leftIcon: '',
  rightIcon: '',
  showTooltip: false,
})

const hasSpace = ref(false)
const activator = ref()

const checkActivatorPosition = () => {
  if (!activator.value) return
  const viewportOffset = activator.value.getBoundingClientRect()
  const top = viewportOffset.top
  const spaceToBottom = window.innerHeight - top
  hasSpace.value = spaceToBottom > 100
}
</script>

<style scoped></style>
