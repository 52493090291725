type TagExtractParams = {
  text: string
  tag: 'span'
}

/* eslint-disable security-node/non-literal-reg-expr */
export default () => {
  const extractValueFromTag = ({ text, tag }: TagExtractParams) => {
    const regex = new RegExp(`<${tag}>(.*?)</${tag}>`)
    const [, value] = text.match(regex) || []

    return value
  }

  const getTextWithoutTag = ({ text, tag }: TagExtractParams) => {
    const regex = new RegExp(`<${tag}>(.*?)</${tag}>`)
    return text.replace(regex, '')
  }

  return {
    extractValueFromTag,
    getTextWithoutTag,
  }
}
