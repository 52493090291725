<template>
  <div class="relative pr-6">
    <p class="text-subhead-4 mb-1 flex items-center">
      <slot name="title" />
      <span
        v-if="$slots.count"
        class="text-caption-2 ml-2 flex size-[18px] items-center justify-center rounded-full bg-primary-100 !text-[10px] text-white"
      >
        <slot name="count" />
      </span>
    </p>
    <p class="text-caption-2 text-black-80">
      <slot name="description" />
    </p>
    <div
      class="absolute right-0 top-0 flex h-full w-6 cursor-pointer items-center justify-center rounded-[6px] text-black-70 transition-all duration-300 hover:bg-black-03 hover:text-black-100"
      @click="emit('select')"
    >
      <UiIcon name="chevron-small-right" size="xs" class="select-none" />
    </div>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (e: 'select'): void
}>()
</script>

<style scoped></style>
