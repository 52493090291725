import qs from 'qs'
import { useAuthStore } from '@/store/auth'
import type { Activity, PaginationResponse } from '@/types'

export const useGetCalendarActivities = async (params: Object): Promise<PaginationResponse> => {
  const queryFormatted = qs.stringify(params, { arrayFormat: 'comma', encode: false })
  const { data, error } = await useAPIFetch(`/calendars/activities?${queryFormatted}`, { key: queryFormatted })

  if (error.value?.statusCode === 401) {
    await useRefreshAccessToken()
    return useGetCalendarActivities(params)
  }

  return data.value as PaginationResponse
}

export const useGetCalendarTimelineActivities = async (params: Object): Promise<PaginationResponse> => {
  const queryFormatted = qs.stringify(params, { arrayFormat: 'comma', encode: false })
  const { data, error } = await useAPIFetch(`/calendars/timeline/activities?${queryFormatted}`, { key: queryFormatted })

  if (error.value?.statusCode === 401) {
    await useRefreshAccessToken()
    return useGetCalendarTimelineActivities(params)
  }

  return data.value as PaginationResponse
}

export const useCreateCalendarActivity = async (activity: Activity): Promise<Activity> => {
  const { data, error } = await useAPIFetch(`/calendars/activities`, { method: 'POST', body: activity })

  if (error.value?.statusCode === 401) {
    await useRefreshAccessToken()
    return useCreateCalendarActivity(activity)
  }

  if (error.value) throw createError(error.value?.message)

  return (data.value as any).data as Activity
}

export const useGetCalendarActivity = async (activityId: number): Promise<Activity> => {
  const { data, error } = await useAPIFetch(`/calendars/activities/${activityId}`)

  if (error.value?.statusCode === 401) {
    await useRefreshAccessToken()
    return useGetCalendarActivity(activityId)
  }

  return (data.value as any).data as Activity
}

export const useUpdateCalendarActivity = async (activity: Partial<Activity>): Promise<Activity> => {
  const { data, error } = await useAPIFetch(`/calendars/activities/${activity.id}`, { method: 'PATCH', body: activity })

  if (error.value?.statusCode === 401) {
    await useRefreshAccessToken()
    return useUpdateCalendarActivity(activity)
  }

  if (error.value) throw createError(error.value?.message)

  return (data.value as any).data as Activity
}

export const useUpdateCalendarActivityStatus = async (activityId: number, statusId: number): Promise<Activity> => {
  const { data, error } = await useAPIFetch(`/calendars/activities/${activityId}/set-status`, {
    method: 'POST',
    body: { status_id: statusId },
  })

  if (error.value?.statusCode === 401) {
    await useRefreshAccessToken()
    return useUpdateCalendarActivityStatus(activityId, statusId)
  } else if (error.value) throw createError(error.value?.message)

  return (data.value as any).data as Activity
}

export const useDeleteCalendarActivity = async (activityId: number): Promise<Activity> => {
  const { data, error } = await useAPIFetch(`/calendars/activities/${activityId}`, {
    method: 'DELETE',
  })

  if (error.value?.statusCode === 401) {
    await useRefreshAccessToken()
    return useDeleteCalendarActivity(activityId)
  }

  return (data.value as any).data as Activity
}

export const useGetCalendarStats = async (): Promise<any> => {
  const { data, error } = await useAPIFetch(`/calendars/activities/statistics`)

  if (error.value?.statusCode === 401) {
    await useRefreshAccessToken()
    return useGetCalendarStats()
  }

  return (data.value as any).data as any
}

export const useGoogleSync = () => {
  const user = useUser()
  const route = useRoute()
  const config = useRuntimeConfig()

  const redirectTo = useCookie('redirectTo')
  redirectTo.value = route.href

  return navigateTo(
    `${config.public.API_URL}google/accounts/${user?.id}/oauth?redirect=${config.public.APP_URL}google/calendars/error-callback`,
    {
      external: true,
    }
  )
}

export const useGoogleSyncCallback = async (googleId: string): Promise<any> => {
  const { data, error } = await useAPIFetch('/google/accounts/sync', { method: 'PATCH', body: { google_id: googleId } })

  if (error.value?.statusCode === 401) {
    await useRefreshAccessToken()
    return useGoogleSyncCallback(googleId)
  }

  return (data.value as any).data
}

export const useRefreshAccessToken = async () => {
  const authStore = useAuthStore()
  const { data, error } = await useAPIFetch(`/google/accounts/${authStore.getUser?.id}/oauth/refresh`, {
    method: 'post',
  })
  if (error.value) throw createError(error.value)

  return data.value as any
}
export const useGetCallLogs = async (params: Object): Promise<PaginationResponse> => {
  const queryFormatted = qs.stringify(params, { arrayFormat: 'comma', encode: false })
  const { data, error } = await useAPIFetch(`/calendars/activities/call-log?${queryFormatted}`, { key: queryFormatted })

  if (error.value) throw createError(error.value)

  return data.value as PaginationResponse
}
