<template>
  <div class="flex flex-col gap-2">
    <div class="flex flex-row flex-wrap items-center gap-2">
      <slot name="default" />
      <div v-if="reason">
        due to
        <b>reason:</b>
        <span class="ml-2 bg-secondary-20 px-1">{{ reason }}</span>
      </div>
      <div v-if="unfreezeDate">Lead unfreeze date set on {{ format(new Date(unfreezeDate), 'PP') }}.</div>
    </div>
    <slot name="notes" />
  </div>
</template>

<script setup lang="ts">
import { format } from 'date-fns'
import type { TimelineItem } from '@/types'

type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()

const reason = props.modelValue.additional?.find((i) => i.field === 'Reason')?.value
const unfreezeDate = props.modelValue.additional?.find((i) => i.field === 'Date when lead will be awake')?.value
</script>

<style scoped></style>
