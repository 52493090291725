<template>
  <div ref="notesDiv" class="sm:h-[calc(100%-100px)] sm:overflow-y-auto" @scroll="checkScroll">
    <transition v-if="!readOnlyNoDetails" name="slideY">
      <UiInputTextareaNote
        v-if="showAddNote && !isMobile"
        v-model="note"
        name="note"
        :disabled="isNoteDisabled"
        placeholder="Your note..."
        button-text="Save"
        class="absolute top-10 z-10 bg-white px-8 pt-4 2xl:px-12"
        :class="addNoteClasses"
        @input="saveNote"
      />
    </transition>
    <div v-if="isMobile" class="flex flex-col gap-2 px-3 pt-3">
      <UiInputTextarea
        v-model="note"
        name="note"
        :disabled="isNoteDisabled"
        placeholder="Your note..."
        :start-rows="2"
      />
      <UiButtonBase id="save-note" type="secondary" :disabled="isNoteDisabled" class="w-full" @click="saveNote"
        >Save
      </UiButtonBase>
    </div>
    <transition name="fade">
      <div
        v-if="showTopOverflowBorder"
        class="absolute top-9 z-20 h-2 w-full border-b border-solid border-black-10 bg-white shadow-4"
      ></div>
    </transition>
    <div
      class="flex flex-col gap-2 px-3 pb-16 sm:gap-4 sm:px-8 2xl:px-12"
      :class="readOnlyNoDetails || isMobile ? 'mt-6 sm:mt-4' : 'mt-24'"
    >
      <div class="flex flex-row items-end gap-1">
        <h4 class="text-subhead-4 sm:text-subhead-1">{{ isMobile ? 'History' : 'Notes' }}</h4>
        <h5 v-if="!loading && !isMobile" class="text-subhead-3 text-black-60">({{ notesItems.length }})</h5>
      </div>
      <UiTimelineBlock v-if="notesItems.length" v-model="notesItems" />
      <UiLoader v-if="loading" class="mb-4" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Lead, TimelineItem } from '@/types'

type Props = {
  leadId: number
  search?: string
  lead?: Lead
  addNoteClasses?: string
  readOnlyNoDetails?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  search: '',
  lead: undefined,
  addNoteClasses: '',
})

const { smaller } = useBreakpoints(useGetBreakpoints())
const isMobile = smaller('xs')

const note = ref('')
const notes = ref<TimelineItem[]>([])
const showAddNote = ref(true)
const notesDiv = ref<HTMLElement>()
const creatingNote = ref(false)

let contentOverflows = ref(false)
const showTopOverflowBorder = ref(false)
let currentScrollY = 0

const loading = ref(true)
const currentPage = ref(0)
const lastPage = ref(0)

onNuxtReady(() => getActivity())

const getActivity = async () => {
  loading.value = true

  currentPage.value += 1

  const response = await useGetLeadActivity(props.leadId, 'note', currentPage.value)
  notes.value.push(...(response.data as TimelineItem[]))
  currentPage.value = response.pagination.current_page

  lastPage.value = response.pagination.total_pages
  loading.value = false
  contentOverflows = computed(() => Number(notesDiv?.value?.scrollHeight) > Number(notesDiv?.value?.offsetHeight))
}

const notesItems = computed({
  get() {
    return notes.value.filter((i) =>
      Object.values(i).some((s) => JSON.stringify(s).toLowerCase().includes(props.search))
    )
  },
  set(value) {
    value.forEach((v) => {
      const item = notes.value.find((a) => a.id === v.id)
      if (item) Object.assign(item, v)
    })
  },
})

const isNoteDisabled = computed(() => creatingNote.value)

const checkScroll = async () => {
  showAddNote.value = currentScrollY > Number(notesDiv?.value?.scrollTop)
  currentScrollY = Number(notesDiv?.value?.scrollTop)

  const scrollIsAlmostBottom =
    Number(notesDiv?.value?.scrollTop) + Number(notesDiv?.value?.clientHeight) + 500 >
    Number(notesDiv?.value?.scrollHeight)

  if (scrollIsAlmostBottom && currentPage.value < lastPage.value && !loading.value) {
    await getActivity()
  }

  if (contentOverflows.value) showTopOverflowBorder.value = Number(notesDiv.value?.scrollTop) > 0
}

const saveNote = async () => {
  if (!note.value) return
  const activityItem = {
    type: 'note',
    comment: note.value,
    lead_id: props.leadId,
  } as unknown as Partial<TimelineItem>
  creatingNote.value = true
  const { data } = await useCreateLeadActivity(activityItem)
  creatingNote.value = false
  notes.value.unshift(data)
  note.value = ''
  notesDiv.value?.scrollTo({ top: 0, behavior: 'smooth' })
}
</script>

<style scoped></style>
