import { defineStore } from 'pinia'

export const useMainStore = defineStore('main', {
  state: () => ({
    apiVersion: '',
  }),
  actions: {
    setApiVersion(version: string) {
      this.apiVersion = version
    },
  },
  getters: {
    getAPIVersion: (state) => state.apiVersion,
  },
})
