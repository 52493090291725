<template>
  <Teleport to="body">
    <transition name="fade">
      <div
        v-if="incomingCall && lead"
        class="fixed inset-x-0 top-0 z-50 size-full overflow-y-auto overflow-x-hidden"
        :class="{ 'bg-black-100/30 text-white backdrop-blur': !isInterestsPanelOpened }"
        @scroll.stop.prevent
      >
        <div class="m-auto flex size-full flex-col items-center justify-center">
          <div
            v-if="!accepted"
            class="mx-auto flex w-11/12 flex-col rounded-2xl bg-black-90 px-6 py-8 text-center shadow md:w-1/2 lg:w-3/12"
          >
            <div class="mb-4">
              <h3 class="mb-1">{{ lead.name }}</h3>
              <div v-if="lead.phone" class="flex flex-row justify-center gap-1">
                +{{ lead.phone_country.phone_code }}
                <span>{{ lead.phone }}</span>
              </div>
            </div>
            <div class="mx-auto mb-2 p-4">
              <UiIcon name="user-filled-circle" class="!h-52 !w-52 text-black-10"></UiIcon>
            </div>
            <div class="mb-8">You have Incoming call from the <span class="text-subhead-1">Active lead.</span></div>
            <!-- Actions -->
            <div class="flex flex-row justify-center gap-4">
              <UiButtonBase id="decline_button" class="!border-error-100 !bg-error-100" @click="terminate">
                <UiIcon name="call-off" class="text-white" />
                Decline
              </UiButtonBase>
              <UiButtonBase id="accept_button" class="!bg-success !border-success-100" @click="confirm"
                ><UiIcon name="call-filled" class="text-white" />Accept</UiButtonBase
              >
            </div>
          </div>
          <div
            v-if="accepted && lead"
            class="m-auto flex h-[696px] w-full flex-row items-start justify-center gap-6 md:w-10/12"
          >
            <div class="relative h-full w-5/12 rounded-3xl bg-white text-black-90">
              <LeadInfo :model-value="lead" readonly class="!h-[580px]" />
              <div class="absolute inset-x-0 bottom-9 mx-8 2xl:mx-12">
                <UiButtonBase
                  id="open_lead"
                  class="w-full"
                  @click="
                    navigateTo(`/leads/${lead.id}`, {
                      open: {
                        target: '_blank',
                      },
                    })
                  "
                  >Open lead details in new tab</UiButtonBase
                >
              </div>
            </div>
            <div class="flex h-full w-7/12 flex-col gap-6">
              <div class="relative block h-[600px] rounded-3xl bg-white text-black-90">
                <div class="relative z-30 mx-8 mt-8 flex flex-row items-center">
                  <UiTabsPrimary model-value="notes" :tabs="[{ id: 'notes', text: 'Notes' }]" />
                </div>
                <LeadActivityNotes :lead-id="lead.id" :lead="lead" add-note-classes="top-[70px]" />
              </div>
              <div
                class="flex h-auto w-full flex-row items-center justify-between rounded-2xl bg-black-90 p-4 text-white shadow"
              >
                <audio ref="remoteAudio"></audio>
                <div class="flex flex-col">
                  <span class="text-subhead-1">{{ lead.name }}</span>
                  <span class="text-body-2">00{{ lead.phone_country.phone_code }}{{ lead.phone }}</span>
                </div>
                <div class="flex flex-row items-center gap-10">
                  <div class="flex flex-row items-center gap-4">
                    <span class="text-body-2">{{ parsedTime }}</span>
                    <button
                      class="flex size-10 items-center justify-center rounded-full bg-error-100 active:bg-error-80"
                      @click="terminate"
                    >
                      <UiIcon name="call-off" class="text-white"></UiIcon>
                    </button>
                  </div>
                  <div class="flex flex-row items-center gap-4">
                    <button
                      class="flex size-10 items-center justify-center rounded-full bg-white/20 active:bg-white/40"
                      :class="{ 'bg-white/40': sessionIsMuted }"
                      @click="toggleMute"
                    >
                      <UiIcon name="micro" class="text-white"></UiIcon>
                    </button>
                    <button
                      class="relative flex size-10 items-center justify-center rounded-full bg-white/20 active:bg-white/40"
                    >
                      <transition name="fade">
                        <LeadCallAudioDevices v-model="showDevices" />
                      </transition>
                      <UiIcon name="settings" class="text-white" @click="showDevices = !showDevices"></UiIcon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <audio ref="remoteAudio"></audio>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script setup lang="ts">
import { intervalToDuration } from 'date-fns'
import { RTCSession } from 'jssip/lib/RTCSession'
import type { IncomingRTCSessionEvent } from 'jssip/lib/UA'
import type { Lead } from '@/types'
import { POPUPS } from '@/components/dynamic/maps'
import { useIOStore } from '~/store/io'
import { useUiStore } from '~/store/ui'

const route = useRoute()
const ioStore = useIOStore()
const uiStore = useUiStore()

let phone = null
const accepted = ref(false)
const lead = ref<Lead>()
const incomingCall = ref(false)
const remoteAudio = ref()
const session = ref<RTCSession>()
const duration = ref(0)
let timer: NodeJS.Timer
const sessionIsMuted = ref(false)
const showDevices = ref(false)

// Slice method delete '/' in the end
const domain = useRuntimeConfig().public.APP_URL.split('.')[1]?.slice(0, -1) || 'app'
const ringingSoundUrl = `https://cdn.thrivestate.${domain}/sounds/ring.mp3`
let ringingAudio: HTMLAudioElement
const isInterestsPanelOpened = computed(() => route.hash.includes('interests'))

onNuxtReady(async () => {
  phone = await ioStore.getUserAgent()
  if (!phone) return

  ringingAudio = new Audio(ringingSoundUrl)
  phone.on('newRTCSession', async function (data: IncomingRTCSessionEvent) {
    session.value = data.session

    if (session.value.direction === 'incoming') {
      if (!data.request?.headers['X-Crm-Lead-Id']) return

      if (data.request?.headers['X-Crm-Ip-Phone'][0]?.raw === 'true') return

      const leadId = Number(data?.request?.headers['X-Crm-Lead-Id'][0]?.raw)
      if (!leadId) return

      lead.value = await useGetLead(leadId)
      incomingCall.value = true
      ringingAudio.play()

      session.value.on('accepted', function () {
        timer = setInterval(() => {
          duration.value += 1
        }, 1000)
      })

      session.value.on('ended', function () {
        terminate()
      })
      session.value.on('failed', function (e) {
        ringingAudio.pause()
        uiStore.showSnackBanner(e.cause, 'error')
        terminate()
      })
    }
  })
})

const parsedTime = computed(() => {
  if (duration.value === 0) return 'Calling...'
  const second = duration.value % 60
  const minute = Math.floor(duration.value / 60) % 60
  return `${minute < 10 ? '0' + minute : minute}: ${second < 10 ? '0' + second : second}`
})

const toggleMute = () => {
  if (session.value?.isMuted()) {
    session.value?.unmute()
  } else {
    session.value?.mute()
  }
  sessionIsMuted.value = !sessionIsMuted.value
}

const terminate = () => {
  try {
    session.value?.terminate()
  } catch (err) {
    // call already terminated
  } finally {
    setTimeout(() => {
      incomingCall.value = false
      accepted.value = false
      session.value = undefined
      clearInterval(timer)
      if (duration.value > 0) {
        showFinishedCallPopup()
      }
    })
  }
}

const showFinishedCallPopup = () => {
  uiStore.showPopup(
    POPUPS.LEAD_CALL_INBOUND_FINISHED,
    { duration: intervalToDuration({ start: 0, end: duration.value * 1000 }) },
    { input: () => navigateTo(`/leads/${lead.value?.id}`, { open: { target: '_blank' } }) }
  )
}

const confirm = async () => {
  const twilioCredentials = await useGetCallsTwillioCredentials()

  session.value?.answer({
    mediaConstraints: {
      audio: true,
    },
    pcConfig: {
      iceServers: [
        {
          urls: ['stun:global.stun.twilio.com:3478'],
        },
        twilioCredentials,
      ],
    },
  })
  duration.value = 0
  accepted.value = true
  ringingAudio.pause()
  setTimeout(() => {
    // Check the current device
    const isSafari = !!window.GestureEvent

    if (isSafari) {
      // This is for Safari.
      session.value?.connection.addEventListener('track', (e: any) => {
        remoteAudio.value.srcObject = e.streams[0]
        remoteAudio.value.play()
      })
    } else {
      // This is for Chrome,Firefox, etc.
      session.value?.connection.addEventListener('addstream', (e: any) => {
        remoteAudio.value.srcObject = e.stream
        remoteAudio.value.play()
      })
    }
  })
}
</script>

<style scoped></style>
