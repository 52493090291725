import maxBy from 'lodash/maxBy'
import minBy from 'lodash/minBy'
import { format } from 'date-fns'
import { utcToZonedTime, getTimezoneOffset } from 'date-fns-tz'
import type { Timezone } from '@/types'

const formatTimeByZone = (zone: string) => {
  const zoneTime = utcToZonedTime(new Date(), zone)
  return format(zoneTime, 'hh:mm a')
}

export default (timezone: Timezone | Timezone[]) => {
  if (Array.isArray(timezone)) {
    const zoneArray = timezone.map((tz) => {
      return { name: tz.name, offset: getTimezoneOffset(tz.name) }
    })
    const minVal = minBy(zoneArray, 'offset')
    const maxVal = maxBy(zoneArray, 'offset')
    const isEqual = minVal?.offset === maxVal?.offset
    return isEqual
      ? formatTimeByZone(minVal?.name!)
      : formatTimeByZone(minVal?.name!) + ' - ' + formatTimeByZone(maxVal?.name!)
  }

  return formatTimeByZone(timezone?.name)
}
