export default {
  status: 'Success',
  message: 'Search result',
  data: [
    {
      index: 'deals',
      id: 1,
      name: 'Anna Boston',
      activities: [
        {
          id: 2581,
          comment: 'Marina will be available tomorrow',
          lead_id: 2198,
        },
        {
          id: 2587,
          comment: 'wwww',
          lead_id: 2198,
        },
      ],
      contacts: [
        {
          id: 1,
          email: 'blanche14@casper.com',
          phone: '+971501587286',
          phone_country_id: 234,
        },
        {
          id: 2,
          email: 'ihaag@hotmail.com',
          phone: '+596685500650',
          phone_country_id: 138,
        },
        {
          id: 3,
          email: 'pcorwin@gulgowski.info',
          phone: '+61405129373',
          phone_country_id: 46,
        },
      ],
      pipelines: {
        id: 4,
        code: 'not_available',
      },
      updated_at: '2024-02-22T12:38:05',
      agent_id: 2,
    },
    {
      index: 'leads',
      id: 2,
      name: 'Marina de Armas',
      activities: [],
      contacts: [
        {
          email: '',
          phone: '+4917656400555',
        },
      ],
      pipelines: {
        id: 3,
        code: 'archive',
      },
      updated_at: '2024-02-07T14:44:12',
      agent_id: 999999,
    },
    {
      index: 'leads',
      id: 3,
      name: 'Marina Danish',
      activities: [],
      contacts: [
        {
          email: '',
          phone: '+4917656400555',
        },
      ],
      pipelines: {
        id: 1,
        code: 'sales',
      },
      updated_at: '2024-02-07T14:43:05',
      agent_id: 999999,
    },
    {
      index: 'deals',
      id: 4,
      name: 'Maria Aniston',
      activities: [
        {
          id: 2581,
          comment: 'note text contains Marina',
          lead_id: 2198,
        },
        {
          id: 2587,
          comment: 'wwww',
          lead_id: 2198,
        },
      ],
      contacts: [
        {
          id: 1,
          email: 'blanche14@casper.com',
          phone: '+971501587286',
          phone_country_id: 234,
        },
        {
          id: 2,
          email: 'ihaag@hotmail.com',
          phone: '+596685500650',
          phone_country_id: 138,
        },
        {
          id: 3,
          email: 'pcorwin@gulgowski.info',
          phone: '+61405129373',
          phone_country_id: 46,
        },
      ],
      pipelines: {
        id: 3,
        code: 'archive',
      },
      updated_at: '2024-02-22T12:38:05',
      agent_id: 2,
    },
    {
      index: 'leads',
      id: 5,
      name: 'Mary Cotton',
      activities: [
        {
          id: 2581,
          comment: 'Control-Y is a we’ll meet in Marina at 11AM.',
          lead_id: 2198,
        },
        {
          id: 2587,
          comment: 'wwww',
          lead_id: 2198,
        },
      ],
      contacts: [
        {
          id: 1,
          email: 'blanche14@casper.com',
          phone: '+971501587286',
          phone_country_id: 234,
        },
        {
          id: 2,
          email: 'ihaag@hotmail.com',
          phone: '+596685500650',
          phone_country_id: 138,
        },
        {
          id: 3,
          email: 'pcorwin@gulgowski.info',
          phone: '+61405129373',
          phone_country_id: 46,
        },
      ],
      pipelines: {
        id: 2,
        code: 'pool',
      },
      updated_at: '2024-02-22T12:38:05',
      agent_id: 2,
    },
  ],
  total: 4,
}
