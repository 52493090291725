import { POPUPS } from '@/components/dynamic/maps'
import { STAGES } from '@/constants'

// Specific transitions from-to that require a different popup
const EXCEPTIONS_POPUP_MAP = new Map<string, Component>([
  [`${STAGES.DORMANT},${STAGES.CLOSED}`, POPUPS.PIPELINE_LEAD_CLOSE],
])

const FROM_STAGE_POPUP_MAP = new Map<string, Component>([
  [STAGES.CLOSED, POPUPS.PIPELINE_LEAD_REOPEN],
  [STAGES.DORMANT, POPUPS.PIPELINE_LEAD_MOVE_FROM_DORMANT],
])

const TO_STAGE_POPUP_MAP = new Map<string, Component>([
  [STAGES.CLOSED, POPUPS.PIPELINE_LEAD_CLOSE],
  [STAGES.DORMANT, POPUPS.PIPELINE_LEAD_MOVE_TO_DORMANT],
  [STAGES.CALL_LATER, POPUPS.PIPELINE_LEAD_MOVE_TO_CALL_LATER],
])

export default (oldStageCode: string, newStageCode: string): Component | undefined => {
  const fromStageKey = Array.from(FROM_STAGE_POPUP_MAP.keys()).find((k) => oldStageCode.includes(k))
  const toStageKey = Array.from(TO_STAGE_POPUP_MAP.keys()).find((k) => newStageCode.includes(k))

  return (
    EXCEPTIONS_POPUP_MAP.get(`${fromStageKey},${toStageKey}`) ||
    FROM_STAGE_POPUP_MAP.get(fromStageKey as string) ||
    TO_STAGE_POPUP_MAP.get(toStageKey as string)
  )
}
