import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import gsapPlugin_HlwIIyl85L from "/usr/src/app/.nuxt/gsapPlugin.mjs";
import switch_locale_path_ssr_8BKffvaEi5 from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_AUP22rrBAc from "/usr/src/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import calendar_client_i1uzu9e7Tc from "/usr/src/app/plugins/calendar.client.ts";
import sentry_client_shVUlIjFLk from "/usr/src/app/plugins/sentry.client.ts";
import vue_google_maps_obX6kp47OW from "/usr/src/app/plugins/vue-google-maps.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  gsapPlugin_HlwIIyl85L,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  plugin_AUP22rrBAc,
  calendar_client_i1uzu9e7Tc,
  sentry_client_shVUlIjFLk,
  vue_google_maps_obX6kp47OW
]