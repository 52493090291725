<template>
  <div class="text-body">
    <template v-if="isCommentExist">
      This Lead has been <b class="text-subhead-1">referred</b> to us by a Partner with a following <b>comments</b>:
      <span class="whitespace-pre-wrap bg-additional-1-30 px-1">{{ comment }}</span>
    </template>
    <template v-else>
      This Lead has been <b class="text-subhead-1">referred</b> to us by a Partner. <br />
      Please consult <b class="text-subhead-1">Partner Manager</b> for more details as needed.
    </template>
  </div>
</template>

<script setup lang="ts">
import type { TimelineItem } from '@/types'

type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()
const comment = ref(props.modelValue.comment)

const isCommentExist = computed(() => comment.value)
</script>

<style scoped lang="scss"></style>
