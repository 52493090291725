import type { ApiResponse, ReleaseVersion } from '@/types'
import { useMainStore } from '@/store'
import { useAuthStore } from '@/store/auth'
import { version } from '@/package.json'
import { ENVS } from '@/constants'

export const useGetReleaseVersions = async (query?: object) => {
  const mainStore = useMainStore()
  const authStore = useAuthStore()
  const currentEnv = useRuntimeConfig().public.APP_ENV

  const { data, error } = await useAPIFetch(`/versions`, { query })
  if (error.value) throw createError(error.value)
  const versions = (data.value as ApiResponse).data as ReleaseVersion[]
  const latestDeployedRelease = versions.find((v) => v.frontend_status === 'deployed')

  // Frontend is not updated
  if (
    latestDeployedRelease &&
    latestDeployedRelease.frontend !== version &&
    !authStore.getIsAdmin &&
    currentEnv !== ENVS.DEV
  ) {
    useLogout(true)
    return window.location.reload()
  }
  if (versions[0]) {
    mainStore.setApiVersion(versions[0].backend)
  }

  return data.value as ApiResponse
}

export const usePostReleaseVersion = async (releaseData: ReleaseVersion) => {
  const { data, error } = await useAPIFetch(`/versions`, { method: 'POST', body: releaseData })
  if (error.value) throw createError(error.value)
  return data.value
}
export const usePatchReleaseVersion = async (releaseData: ReleaseVersion, id: number) => {
  const { data, error } = await useAPIFetch(`/versions/${id}`, { method: 'PATCH', body: releaseData })
  if (error.value) throw createError(error.value)
  return data.value
}
export const useDeleteReleaseVersion = async (id: number) => {
  const { data, error } = await useAPIFetch(`/versions/${id}`, { method: 'DELETE' })
  if (error.value) throw createError(error.value)
  return data.value
}
