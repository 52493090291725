<template>
  <div class="flex flex-col gap-2">
    <div class="flex flex-row flex-wrap items-center gap-2">
      <slot name="default" />
      <div>
        due to <b>reason: </b>
        <span class="mx-1 bg-additional-1-30 px-1">
          {{ reason }}
        </span>
      </div>
    </div>
    <slot name="notes" />
  </div>
</template>

<script setup lang="ts">
import type { TimelineItem } from '@/types'

type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()

const reason = props.modelValue.additional?.find((i) => i.field === 'Reason')?.value
</script>

<style scoped></style>
