<template>
  <div
    v-if="modelValue"
    style="overflow: visible !important"
    class="flex flex-row items-center justify-end max-[1400px]:flex-wrap md:gap-3"
    :class="[isClosedLost ? 'gap-1' : 'gap-4']"
  >
    <div
      v-if="isClosedLost"
      class="text-caption flex min-h-[40px] items-center rounded-lg bg-additional-3-30 px-4 py-2 max-[1400px]:order-4 max-[1400px]:whitespace-normal"
    >
      All actions are disabled for closed lead. Reopen the lead to resume your work
    </div>
    <div v-if="isReopenVisible" class="order-5 flex w-full flex-row items-center md:hidden">
      <UiButtonBase
        id="reopen"
        class="text-subhead-3 w-full !rounded-lg"
        type="primary"
        size="small"
        @click="emits('reopen')"
      >
        Reopen
      </UiButtonBase>
    </div>
    <UiButtonGhost v-if="showBackToQueue" id="back-to-queue" @click="backToQueue">
      <UiIcon name="arrow-big-left" class="" /> Back to Queue</UiButtonGhost
    >
    <div
      v-if="showTimer"
      id="timer-for-call"
      ref="timerForCall"
      class="text-body -ml-10 flex items-center gap-3 rounded-xl border border-primary-100 bg-white px-3 py-1"
    >
      <p>
        <span class="text-subhead-1">Call</span> within
        <span class="text-subhead-1">{{ timeMakeFirstCall }} seconds</span> or lead returns to Queue.
      </p>
      <div
        class="rounded-lg bg-primary-100 px-2 py-1 text-white"
        :class="{ '!bg-black-20 !text-black-60': callStarted }"
      >
        <span v-if="timeDifference && timeDifference > 0">{{ secondsToTime(timeDifference) }}</span>
        <span v-else-if="isSDRTourActive">{{ secondsToTime(timeMakeFirstCall) }}</span>
        <span v-else>00:00</span>
      </div>
    </div>
    <template v-if="isMobile ? !isClosedLost : true">
      <div class="flex-1 md:w-auto md:flex-none">
        <LeadCall
          v-model="modelValue"
          :is-mobile="isMobile"
          :disabled="isClosedLost || isUnverifiedLead"
          :update-lead="isSdrQueue"
          :full-width="isMobile"
          @called="$emit('called', $event)"
          @call-started="callStarted = true"
        />
      </div>
      <div class="flex flex-row items-center gap-4 md:w-auto md:gap-3">
        <template v-if="useGetShowPhoneLead(modelValue)">
          <div v-if="isMobile">
            <UiIcon
              name="whatsapp_call"
              :class="[isClosed ? 'pointer-events-none text-black-40' : 'text-[#61FD7D]']"
              size="lg"
              @click="sendWhatsAppMessage(modelValue!)"
            />
          </div>
          <UiButtonBase
            v-else
            id="whatsapp_message"
            type="secondary"
            :disabled="isUnverifiedLead || isClosed"
            @click="sendWhatsAppMessage(modelValue!)"
          >
            <UiIcon name="whatsapp" :class="isUnverifiedLead || isClosed ? 'text-disabled-40' : 'text-whatsapp'" />
            WhatsApp
          </UiButtonBase>
        </template>
        <UiButtonBase id="activity" :disabled="isClosed" type="secondary" :icon="isMobile" @click="createActivity">
          <UiIcon name="calendar" />
          <span class="hidden md:block">Activity</span>
        </UiButtonBase>
        <UiButtonBase v-if="isMobile" id="activity" type="secondary" icon @click="redirectToAssign">
          <UiIcon name="assign" />
        </UiButtonBase>
        <UiMenuActions
          v-if="getAvailableActions().length && !isMobile"
          name="stage_actions"
          :items="getAvailableActions()"
          :width="200"
          class="z-40"
          align-right
          @action="$event.handler($event)"
        >
          <template #activator="{ onClick }">
            <UiButtonBase
              id="more-actions"
              type="secondary"
              icon
              :disabled="isUnverifiedLead || isClosed"
              @click="onClick()"
            >
              <UiIcon name="more-vertical" class="rotate-90" />
            </UiButtonBase>
          </template>
        </UiMenuActions>
        <UiTooltip v-if="!isMobile" name="next-lead">
          <template #activator>
            <UiButtonGhost id="next" :disabled="!nextLeadId" class="ml-4" @click="goToNextLead">
              Next <UiIcon name="arrow-big-right" />
            </UiButtonGhost>
          </template>
          <template v-if="!nextLeadId && !loadingNextLead" #content>
            The list has run out of leads. Please return to the pipeline and refresh it.
          </template>
        </UiTooltip>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { differenceInSeconds, addSeconds } from 'date-fns'
import type { EditSidePanelProps } from '../activity/create/EditSidePanel.vue'
import type { Activity, DynamicComponent, InputItem, Lead, LeadsFilters } from '@/types'
import { STAGES, PIPELINES, PERMISSIONS, CUSTOM_EVENTS, STEPS, POOL_STAGES, CALENDAR_ACTIVITY_TYPES } from '@/constants'
import { POPUPS, SIDE_PANELS } from '@/components/dynamic/maps'
import { useAuthStore } from '~/store/auth'
import { useUiStore } from '@/store/ui'

const emits = defineEmits(['called', 'reopen'])

const uiStore = useUiStore()
const authStore = useAuthStore()

type Props = {
  isMobile?: boolean
}

const modelValue = defineModel<Lead>()

const props = withDefaults(defineProps<Props>(), {
  isMobile: false,
})

const isClosed = inject<Ref<boolean>>('isClosed')
const isClosedLost = inject<Ref<boolean>>('isClosedLost')
const inInArchive = inject<Ref<boolean>>('inInArchive')
const isSDRTourActive = inject<Ref<boolean>>('isSDRTourActive')

// logic for SDR queue
const LEAD_QUEUE_TIME_TO_RETURN_TO_QUEUE_SECONDS_CHECKBOX = 'lead_queue_time_to_return_to_queue_seconds_checkbox'
const LEAD_QUEUE_TIME_TO_RETURN_TO_QUEUE_SECONDS = 'lead_queue_time_to_return_to_queue_seconds'

const loadingNextLead = ref(false)
const nextLeadId = ref<number>()
const firstCallCheckbox = ref(false)
const timeMakeFirstCall = ref(0)
const callStarted = ref(false)
const pickedTime = useCookie('pickedTime')
const fromQueue = computed(() => {
  return window.history.state.back?.includes('pipelines/queue')
})

const showBackToQueue = computed(() => {
  return modelValue.value?.pipeline.code !== PIPELINES.QUEUE && authStore.getIsSdr && fromQueue.value
})

const isSdrQueue = computed(() => {
  return modelValue.value?.pipeline.code === PIPELINES.QUEUE && authStore.getIsSdr && fromQueue.value
})

const showTimer = computed(() => {
  return (isSdrQueue.value || isSDRTourActive?.value) && firstCallCheckbox.value
})

const backToQueue = () => {
  navigateTo(`/pipelines/queue`)
}

onNuxtReady(async () => {
  if (isSdrQueue.value || isSDRTourActive?.value) await getSdrQueueSettings()
  await getNextLead()
})

const getSdrQueueSettings = async () => {
  const [
    {
      settings: { lead_queue_time_to_return_to_queue_seconds_checkbox: checkboxCallSetting },
    },
    {
      settings: { lead_queue_time_to_return_to_queue_seconds: timeCallSetting },
    },
  ] = await Promise.all([
    useGetSettingsByCode(LEAD_QUEUE_TIME_TO_RETURN_TO_QUEUE_SECONDS_CHECKBOX),
    useGetSettingsByCode(LEAD_QUEUE_TIME_TO_RETURN_TO_QUEUE_SECONDS),
  ])

  firstCallCheckbox.value = !!+checkboxCallSetting
  timeMakeFirstCall.value = Number(timeCallSetting)
}

const formattedDate = () => {
  return differenceInSeconds(addSeconds(new Date(pickedTime.value), timeMakeFirstCall.value), new Date())
}

const secondsToTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  const minutesString = minutes < 10 ? `${minutes}` : `${minutes}`
  const secondsString = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`
  return `${minutesString}:${secondsString}`
}

const timeDifference = ref<number | null>(null)

let timeoutId = null

const updateTimeDifference = () => {
  if (!showTimer.value || callStarted.value || (timeDifference.value && timeDifference.value <= 0)) {
    clearTimeout(timeoutId)
  } else {
    timeDifference.value = formattedDate()
    timeoutId = setTimeout(updateTimeDifference, 1000)
  }
}

onMounted(() => {
  timeoutId = setTimeout(updateTimeDifference, 1000)
})
onBeforeUnmount(() => {
  clearTimeout(timeoutId)
})

// logic for SDR queue

const isShadowLead = inject<Ref<boolean>>('isShadowLead')
const isUnverifiedLead = computed(() => modelValue.value!.stage.code === POOL_STAGES.UNVERIFIED)
const isVerifiedLead = computed(() => modelValue.value!.stage.code === POOL_STAGES.VERIFIED)

const isReopenVisible = computed(() => {
  if (authStore.getIsAgent && inInArchive?.value) {
    return false
  }

  return isClosedLost?.value
})

const REASSIGN_ACTION_ID = 'reassign'

const actionItems = computed(() => [
  {
    text: 'Reopen',
    icon: 'rotate-left',
    value: {
      handler: (value: DynamicComponent) => toggleActionPopup(value),
      component: POPUPS.PIPELINE_LEAD_REOPEN,
      data: {
        lead: modelValue.value,
      },
      events: {
        create: () => {
          uiStore.showSidePanel(SIDE_PANELS.PIPELINE_LEAD_CREATE, undefined, {
            created: () => refreshLead(),

            duplicate: (lead: Lead) => {
              uiStore.showPopup(
                POPUPS.PIPELINE_LEAD_DUPLICATE,
                {
                  lead,
                },
                {
                  created: () => refreshLead(),
                }
              )
            },
            suggest: (lead: Lead, duplicateId: string) => {
              uiStore.showPopup(
                POPUPS.PIPELINE_LEAD_DUPLICATE_SUGGEST,
                { lead, duplicateId },
                {
                  created: () => refreshLead(),
                }
              )
            },
          })
        },
        input: () => refreshLead(),
      },
    },
    condition: () => isClosedLost?.value,
  },
  {
    text: 'Release to Pool',
    icon: 'refresh-ccw',
    value: {
      handler: (value: DynamicComponent) => toggleActionPopup(value),
      component: POPUPS.PIPELINE_LEAD_MOVE_TO_POOL,
      data: {
        lead: modelValue.value,
      },
      events: {
        input: () => redirectToTable(),
      },
    },
    condition: () =>
      modelValue.value!.stage.code !== STAGES.CLOSED && modelValue.value!.pipeline.code !== PIPELINES.POOL,
  },
  {
    text: 'Re-assign lead',
    id: REASSIGN_ACTION_ID,
    icon: 'up-right',
    value: {
      handler: (value: DynamicComponent) => toggleActionPopup(value),
      component: POPUPS.PIPELINE_LEAD_ASSIGN_AGENT,
      data: {
        selectedLeads: [modelValue.value],
      },
      events: {
        input: (user: InputItem) => {
          uiStore.showSnackBanner(`Lead was successfully assigned and moved to ${user?.text} Pipeline.`)
          if (authStore.getIsSdr) {
            return navigateTo('/pipelines/sales/all')
          } else {
            refreshLead()
          }
        },
      },
    },
  },
  {
    text: 'Send to Queue',
    icon: 'user-group',
    value: {
      handler: (value: DynamicComponent) => toggleActionPopup(value),
      component: POPUPS.PIPELINE_LEAD_SEND_TO_QUEUE,
      data: {
        leadId: modelValue.value!.id,
      },
      events: {
        input: () => refreshLead(),
      },
    },
    condition: () => useHasPermissions([PERMISSIONS.CAN_SEND_LEAD_TO_QUEUE]),
  },
  {
    text: 'Create shadow lead',
    icon: 'copy',
    value: {
      handler: (value: DynamicComponent) => toggleActionPopup(value),
      component: POPUPS.PIPELINE_LEAD_CREATE_SHADOW,
      data: {
        leadId: modelValue.value!.id,
      },
      events: {
        input: () => refreshLead(),
      },
    },
    condition: () => useHasPermissions([PERMISSIONS.CAN_CREATE_SHADOW_LEADS]) && !isShadowLead?.value,
    disabled: isVerifiedLead.value,
  },
])

const getAvailableActions = () => {
  return actionItems.value.filter((a) => (!a.condition ? true : a.condition()))
}

const toggleActionPopup = ({ component, data, events = {} }: DynamicComponent) => {
  uiStore.showPopup(component!, data, events)
}

const redirectToTable = () => {
  setTimeout(() => {
    authStore.getIsAgent ? navigateTo('/pipelines/sales/all') : navigateTo('/pipelines/pool')
  }, 300)
}

const sendWhatsAppMessage = async (lead: Lead) => {
  if (lead.step?.code === STEPS.FRESH) {
    await useStartedWhatsApp(lead.id)
    document.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.REFRESH_LEAD_DATA))
  }
  navigateTo(
    `https://api.whatsapp.com/send?phone=+${lead.phone_country.phone_code}${lead.phone}&text=Hey ${lead.name},`,
    {
      external: true,
      open: {
        target: '_blank',
      },
    }
  )
}

const redirectToAssign = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  setTimeout(() => {
    navigateTo(`/leads/${modelValue.value!.id}/assign`)
  })
}

const createActivity = () => {
  if (props.isMobile) {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    setTimeout(() => {
      navigateTo(`/leads/${modelValue.value!.id}/activity`)
    })
  } else {
    showCreateActivitySidePanel()
  }
}

const showCreateActivitySidePanel = (props?: EditSidePanelProps) => {
  uiStore.showSidePanel(
    SIDE_PANELS.ACTIVITY_CREATE_EDIT,
    { lead: modelValue.value, ...props },
    {
      created: (activity: Activity) => activityCreated(activity),
    }
  )
}

const activityCreated = (activity: Activity) => {
  const forAnotherUser = activity.user?.id !== authStore.getUser.id

  if (forAnotherUser) {
    if (authStore.getIsSdr) {
      uiStore.showPopup(
        POPUPS.PIPELINE_LEAD_ASSIGN_AGENT,
        {
          selectedLeads: [modelValue.value],
          userId: activity.user?.id,
        },
        {
          input: (user: InputItem) => {
            uiStore.showSnackBanner(
              `Meeting between ${user?.text} and ${modelValue.value?.name} was created. Lead was successfully re-assigned.`
            )
            return navigateTo('/pipelines/sales/all')
          },
        }
      )
    } else {
      uiStore.showSnackBanner(`${activity.type?.name} was planned for ${activity.user?.name} and added to his calendar`)
      refreshLead()
    }
  } else {
    refreshLead()
  }
}

const refreshLead = () => {
  document.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.REFRESH_LEAD_HISTORY))
  document.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.REFRESH_LEAD_DATA))
}

const timerForCall = ref<HTMLDivElement | null>(null)

const isLeadActionsMounted = useState('isLeadActionsMounted', () => false)

watchEffect(() => {
  if (timerForCall.value) {
    isLeadActionsMounted.value = true
  }
})

onMounted(() => {
  useMittListen('tour:SDRQueue:createActivity', () => {
    showCreateActivitySidePanel({
      synced: true,
      activityType: CALENDAR_ACTIVITY_TYPES.MEETING,
      getActivities: false,
      ...(useHasPermissions([PERMISSIONS.CREATE_SHARED_ACTIVITY]) ? { forAnotherUser: true } : {}),
    })
  })

  useMittListen('lead:reassign', () => {
    const reassignAction = actionItems.value.find((actions) => actions.id === REASSIGN_ACTION_ID)

    if (reassignAction) {
      uiStore.showPopup(
        reassignAction.value.component!,
        {
          ...reassignAction.value.data,
        },
        reassignAction.value.events
      )
    } else {
      throw new Error('Reassign action not found')
    }
  })
})

onUnmounted(() => {
  isLeadActionsMounted.value = false

  useMittRemove('lead:reassign')
})

const getNextLead = async () => {
  const uiStore = useUiStore()
  const filters = ref(
    useCreateFilters({
      ...uiStore.tableFilters[modelValue.value!.pipeline.code as keyof typeof uiStore.tableFilters],
    }) as LeadsFilters
  )
  const isGridView = uiStore.salesPipelineGridView && modelValue.value!.pipeline.code === PIPELINES.SALES

  loadingNextLead.value = true
  const { data, error } = await useGetNextLead(
    modelValue.value!.pipeline.id,
    modelValue.value!.id,
    filters.value,
    isGridView
  )
  loadingNextLead.value = false
  if (error.value) {
    return uiStore.showSnackBanner(error.value.message, 'error')
  }
  nextLeadId.value = data.value?.data?.id
}

const goToNextLead = () => {
  return navigateTo(`/leads/${nextLeadId.value}`)
}
</script>

<style scoped></style>
