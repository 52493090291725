import { useFetch } from '#app'
import * as Sentry from '@sentry/vue'
import { useAuthStore } from '@/store/auth'
import { useUiStore } from '~/store/ui'

type useFetchType = typeof useFetch

const logoutExceptions = ['/calendar']

export const useAPIFetch: useFetchType = (path, options = {}) => {
  const config = useRuntimeConfig()
  const authStore = useAuthStore()
  const uiStore = useUiStore()

  options.baseURL = config.public.API_URL
  options.headers = {
    ...options.headers,

    Authorization: `Bearer ${authStore.getToken}`,
  }
  options.key = crypto.randomUUID()
  options.onResponseError = ({ request, response }) => {
    switch (response.status) {
      case 401:
        if (!logoutExceptions.some((e) => request.toString().includes(e))) {
          useLogout(false)
        }
        break
      case 422:
        // Don't show snack bar with validation errors
        break
      case 404:
        throw showError({
          message: response._data.message,
          data: response._data,
          statusCode: response.status,
        })
      case 400:
        throw showError({
          message: response._data.message,
          data: response._data,
          statusCode: response.status,
        })
      case 500:
        throw showError({
          message: response._data.message,
          data: response._data,
          statusCode: response.status,
        })
      default:
        uiStore.showSnackBanner(response._data.message, 'error')
    }

    Sentry.captureMessage(response?._data?.message, 'error')

    throw createError({
      message: response._data.message,
      data: response._data,
      statusCode: response.status,
    })
  }

  return useFetch(path, options)
}
