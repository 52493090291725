<template>
  <div class="flex flex-col gap-2">
    <div class="text-body-2 sm:text-body">
      The
      <span class="text-subhead-2">{{ modelValue.direction === 'inbound' ? 'Incoming' : 'Outgoing' }} call</span>
      was made. Duration
      {{ duration }}.
    </div>
    <div v-if="!readOnlyNoDetails" class="text-body-2 sm:text-body">
      Call from number
      <span :class="{ 'blur-sm': modelValue.from?.toString() === 'hidden' }">{{ modelValue.from }}</span> to number
      <span :class="{ 'blur-sm': modelValue.to?.toString() === 'hidden' }">{{ modelValue.to }}</span
      >.
    </div>
    <div class="flex flex-col-reverse gap-2 sm:flex-col">
      <div class="flex flex-col items-center justify-between gap-2 sm:flex-row">
        <transition name="fade" mode="out-in">
          <component
            :is="isMobile ? UiButtonBase : UiButtonGhost"
            v-if="!playing"
            id="play"
            size="small"
            class="text-subhead-2 w-full !rounded-lg text-primary-100 sm:w-fit"
            @click="startPlaying"
          >
            <UiIcon name="play" :class="isMobile ? 'text-white' : 'text-primary-100'" />
            Listen to the call record
          </component>
          <div v-else class="flex h-8 w-full items-center justify-center sm:w-fit">
            <audio ref="audioElement" controls>
              <source :src="modelValue.link" type="audio/mp3" />
            </audio>
          </div>
        </transition>

        <div v-if="modelValue.link" class="flex w-full gap-2.5 sm:w-fit">
          <UiTooltip :name="`copy-link-${modelValue.id}`" class="size-full">
            <template #activator>
              <UiButtonBase
                id="link"
                size="small"
                type="secondary"
                :icon="!isMobile"
                class="w-full !rounded-lg"
                @click="copyLink(modelValue.link)"
              >
                <Transition name="fade" mode="out-in">
                  <UiIcon v-if="copied" name="check-filled" class="fill-white" />
                  <UiIcon v-else name="link-normal" />
                </Transition>
                <span v-if="isMobile">Link to call</span>
              </UiButtonBase>
            </template>
            <template v-if="copied" #content>Copied!</template>
          </UiTooltip>
          <UiButtonBase
            v-if="!isMobile"
            id="download"
            size="small"
            type="secondary"
            icon
            class="!rounded-lg"
            @click="downloadRecording(modelValue.link)"
          >
            <UiIcon name="download" />
          </UiButtonBase>
        </div>
      </div>
      <hr v-if="isMobile" class="border-t-2 border-solid border-black-10" />
      <div v-if="modelValue.outbound_call_info">
        <span class="mb-1 text-xs text-black-40">Call outcome:</span>
        <span class="block text-base text-black-100"
          >{{ getOutboundOutcomeName(modelValue.outbound_call_info)
          }}{{ getConnectionQuality(modelValue.outbound_call_info) }}
        </span>
      </div>
    </div>

    <div
      class="absolute right-4 top-4 flex h-6 w-fit flex-row items-center justify-between gap-1 gap-x-2 rounded-lg px-2 py-0.5 sm:h-8 sm:py-1"
      :class="
        modelValue.direction === 'inbound'
          ? 'bg-additional-2-10 text-additional-2-120'
          : 'bg-primary-10 text-primary-100'
      "
    >
      <UiIcon v-if="modelValue.direction === 'inbound'" name="call-inbound" size="xs" />
      <UiIcon v-else name="call-outgoing" :size="isMobile ? 'xxs' : 'xs'" />
      <h5 class="text-subhead-4 sm:text-subhead-3">
        {{ modelValue.direction === 'inbound' ? 'Incoming' : 'Outgoing' }}
      </h5>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { OutcomeCallInfo, TimelineItem } from '@/types'
import { PERMISSIONS, CALL_OUTBOUND_CONNECTION } from '~/constants'
import { useAuthStore } from '~/store/auth'
import { useLibrariesStore } from '@/store/libraries'

const authStore = useAuthStore()
const librariesStore = useLibrariesStore()

const UiButtonBase = resolveComponent('UiButtonBase')
const UiButtonGhost = resolveComponent('UiButtonGhost')

type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()

const { smaller } = useBreakpoints(useGetBreakpoints())
const isMobile = smaller('xs')

const readOnlyNoDetails = computed(() => authStore.getPermissions.includes(PERMISSIONS.SEE_TIMELINE_NO_DETAILS))

const audioElement = ref()
const playing = ref(false)

const seconds = props.modelValue.duration % 60
const minutes = Math.floor(props.modelValue.duration / 60) % 60
const duration = `${minutes < 10 ? '0' + minutes : minutes}m:${seconds < 10 ? '0' + seconds : seconds}s`

const startPlaying = () => {
  playing.value = true
}

const copied = ref(false)

const copyLink = async (link: string) => {
  try {
    await navigator.clipboard.writeText(link)
    copied.value = true
    setTimeout(() => (copied.value = false), 1000)
  } catch (error: any) {
    throw createError(error.message)
  }
}

const downloadRecording = async (link: string) => {
  try {
    const response = await useFetch(`/api/download-file?link=${link}`)
    const blob = await response.data.value
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = link?.split('\\').pop().split('/').pop()
    a.click()
    URL.revokeObjectURL(url)
  } catch (error: any) {
    throw createError(error.message)
  }
}

const getOutboundOutcomeName = ({ outcome: outcomeId }: OutcomeCallInfo) => {
  const name = librariesStore.getCallOutboundOutcomes.find(({ id }) => id === outcomeId)?.name

  return name ? `${name}; ` : ''
}

const getConnectionQuality = ({ quality }: OutcomeCallInfo) => {
  return CALL_OUTBOUND_CONNECTION.GOOD === quality ? 'Good connection' : 'Bad connection'
}
</script>

<style scoped>
audio {
  height: 30px;
}
audio::-webkit-media-controls-enclosure {
  background: none;
  max-height: 30px;
}
</style>
