<template>
  <li>
    <span
      class="absolute -left-4 hidden size-8 items-center justify-center rounded-full bg-primary-05 ring-8 ring-white sm:flex"
    >
      <UiIcon
        :name="itemsTypeMap.get(modelValue.type.code)?.icon || 'info-circle'"
        size="xs"
        class="hidden text-primary-100 sm:block"
      ></UiIcon>
    </span>

    <div
      ref="timelineItem"
      class="relative rounded-xl border border-solid border-black-10 p-4 transition-colors duration-500 sm:ml-8 sm:rounded-2xl"
      :class="{ 'bg-primary-05': !isItemViewed }"
    >
      <div class="flex items-center gap-x-2">
        <p v-if="modelValue.created_at" class="text-caption flex flex-row items-center gap-1 py-1 text-black-70">
          <span v-if="isToday(new Date(modelValue.created_at))">Today |</span>
          {{ format(new Date(modelValue.created_at), `d MMMM yyyy, HH:mm`) }}
        </p>
      </div>

      <div :class="{ 'pt-3': !itemsTypeMap.get(modelValue.type.code)?.hasActions }">
        <component
          :is="itemsTypeMap.get(modelValue.type.code)?.component"
          v-if="itemsTypeMap.get(modelValue.type.code)"
          :model-value="modelValue"
          @update:model-value="$emit('update:modelValue', $event)"
        ></component>
      </div>
    </div>
  </li>
</template>

<script setup lang="ts">
import { format, isToday } from 'date-fns'
import type { Component } from 'nuxt/schema'
import type { TimelineItem } from '@/types'

defineEmits(['update:modelValue'])

type Props = {
  modelValue: TimelineItem
  module: 'lead' | 'deal'
  canViewHighlightedTimeline?: boolean
}
const props = defineProps<Props>()

const vModel = defineModel<TimelineItem>('modelValue', {
  required: true,
})

type Item = {
  icon: string
  component: Component
  hasActions?: boolean
  viewMode?: boolean
}

const leadTypesMaps = new Map<string, Item>([
  [
    'referral_comment',
    { icon: 'edit-page', component: resolveComponent('UiTimelineItemLeadReferralComment') as Component },
  ],
  [
    'referral_note',
    { icon: 'edit-page', component: resolveComponent('UiTimelineItemLeadReferralNote') as Component, viewMode: true },
  ],
  ['note', { icon: 'edit-page', component: resolveComponent('UiTimelineItemLeadNote') as Component, hasActions: true }],
  [
    'referral_info_updated',
    {
      icon: 'edit-page',
      component: resolveComponent('UiTimelineItemLeadReferralInfoUpdated') as Component,
      viewMode: true,
    },
  ],
  ['call', { icon: 'call', component: resolveComponent('UiTimelineItemLeadCall') as Component }],
  ['information', { icon: 'info-circle', component: resolveComponent('UiTimelineItemLeadInformation') as Component }],
  [
    'transition',
    { icon: 'operations-forward', component: resolveComponent('UiTimelineItemLeadTransition') as Component },
  ],
  [
    'calendar_task',
    { icon: 'calendar', component: resolveComponent('UiTimelineItemLeadCalendarActivity') as Component },
  ],
  [
    'calendar_meeting',
    { icon: 'calendar', component: resolveComponent('UiTimelineItemLeadCalendarActivity') as Component },
  ],
  [
    'calendar_call',
    { icon: 'calendar', component: resolveComponent('UiTimelineItemLeadCalendarActivity') as Component },
  ],
  [
    'calendar_all_day',
    { icon: 'calendar', component: resolveComponent('UiTimelineItemLeadCalendarActivity') as Component },
  ],
  ['lead_changes', { icon: 'info-circle', component: resolveComponent('UiTimelineItemLeadChanges') as Component }],
])

const dealTypesMap = new Map<string, Item>([
  [
    'transition',
    { icon: 'operations-forward', component: resolveComponent('UiTimelineItemDealTransition') as Component },
  ],
  [
    'note',
    {
      icon: 'info-circle',
      component: resolveComponent('UiTimelineItemDealNote') as Component,
      hasActions: true,
    },
  ],
  [
    'info_change',
    {
      icon: 'edit-page',
      component: resolveComponent('UiTimelineItemDealInfoChange') as Component,
    },
  ],
])

const modules = new Map<string, Map<string, Item>>([
  ['lead', leadTypesMaps],
  ['deal', dealTypesMap],
])

const itemsTypeMap = modules.get(props.module) || leadTypesMaps

const currentItem = computed(() => itemsTypeMap.get(props.modelValue.type.code))

const isItemViewed = computed(() => (isViewMode.value ? props.modelValue.is_viewed : true))

const isViewMode = computed(() => currentItem.value?.viewMode && props.canViewHighlightedTimeline)

const timelineItem = ref(null)

const { stop } = useIntersectionObserver(
  isViewMode.value ? timelineItem : null,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      useViewActivity({
        id: props.modelValue.id,
        isViewed: true,
      }).then(({ data }) => {
        vModel.value.is_viewed = Boolean(data.value?.data.is_viewed)
        stop()
      })
    }
  },
  {
    threshold: 0.3,
  }
)

onMounted(() => {
  if (!isViewMode.value) {
    stop()
  }
})
</script>

<style scoped></style>
