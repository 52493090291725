<template>
  <div>
    <div
      :id="`${name}_activator`"
      class="h-full w-fit"
      @mouseover="checkActivatorPosition"
      @mouseleave="removeStyleElement"
    >
      <slot name="activator" @click="removeStyleElement"></slot>
    </div>

    <Teleport to="body">
      <div
        v-if="$slots.content && !disabled"
        :id="`${name}_tooltip`"
        class="text-body-2 invisible fixed z-50 inline-block w-min min-w-[150px] max-w-[300px] whitespace-normal rounded-lg bg-black-100 px-3 py-2 font-medium text-white opacity-0 shadow-sm transition-all duration-200"
        :class="[tooltipClasses]"
      >
        <div
          :id="`${name}_arrow`"
          class="absolute bottom-full left-1/2 ml-[-5px] h-fit border-[5px] border-solid border-transparent border-b-black-100 content-['']"
        ></div>
        <slot name="content"></slot>
      </div>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
type Props = {
  name: string
  position?: string
  tooltipClasses?: string
  width?: number
  positionRight?: boolean
  positionLeft?: boolean
  positionTop?: boolean
  disabled?: boolean
}

const props = defineProps<Props>()

const removeStyleElement = () => {
  const tooltip = document.getElementById(`${props.name}_tooltip`)
  if (tooltip) {
    tooltip.style.visibility = 'hidden'
    tooltip.style.opacity = '0'
  }
}

const checkActivatorPosition = () => {
  const activator = document.getElementById(`${props.name}_activator`)
  if (!activator) return

  const top = activator.getBoundingClientRect().top
  const left = activator.getBoundingClientRect().left

  setTimeout(() => {
    const tooltip = document.getElementById(`${props.name}_tooltip`)
    const arrow = document.getElementById(`${props.name}_arrow`)

    if (tooltip && arrow) {
      const spaceToBottom = window.innerHeight - top
      let width = Number(props.width || activator.offsetWidth)
      if (width < 150) width = 150
      tooltip.style.width = `${width}px`

      //   First calculate vertical position based on props or space available
      if (props.positionRight || props.positionLeft) {
        tooltip.style.top = `${top + activator.offsetHeight / 2 - tooltip.offsetHeight / 2}px`
      } else if (spaceToBottom < tooltip.offsetHeight + Number(activator.offsetHeight) || props.positionTop) {
        tooltip.style.top = `${top - tooltip.offsetHeight - 5}px`
      } else {
        tooltip.style.top = `${top + Number(activator.offsetHeight) + 5}px`
      }

      const spaceToRight = window.innerWidth - left
      const spaceToLeft = left
      if (props.positionTop) {
        tooltip.style.left = `${left + (activator.offsetWidth - width) / 2}px`
        arrow.style.left = '50%'
        arrow.style.top = '100%'
        arrow.style.transform = 'rotate(180deg)'
      } else if (props.positionRight) {
        // Position tooltip to the right
        tooltip.style.left = `${left + activator.offsetWidth + 10}px`
        arrow.style.left = '15%'
        arrow.style.transform = 'rotate(-90deg)'
        arrow.style.top = '0'
        arrow.style.bottom = '0'
        arrow.style.margin = 'auto'
      } else if (props.positionLeft) {
        // Position tooltip to the left
        tooltip.style.left = `${left - tooltip.offsetWidth - 10}px`
        arrow.style.left = '100%'
        arrow.style.transform = 'rotate(90deg)'
        arrow.style.top = '0'
        arrow.style.bottom = '0'
        arrow.style.margin = 'auto'
      } else if (spaceToRight > width && spaceToLeft > width) {
        // There's space on both sides so position tooltip in the center
        tooltip.style.left = `${left + (activator.offsetWidth - width) / 2}px`
        arrow.style.left = '50%'
      } else if (spaceToRight < width) {
        // There's no space to the right so position tooltip and arrow to the right edge of the activator
        tooltip.style.left = `${left - width + Number(activator.offsetWidth)}px`
        arrow.style.left = '92%'
      } else {
        // There's no space to the left so position tooltip and arrow to the left edge of the activator
        tooltip.style.left = `${left}px`
        arrow.style.left = '10%'
      }
      tooltip.style.visibility = 'visible'
      tooltip.style.opacity = '100'
    }
  })
}
</script>

<style scoped></style>
