import { defineStore } from 'pinia'
import merge from 'lodash/merge'
import type { WhatsappChat, WhatsappMessage } from '@/types'

type WhatsappStore = {
  disconnected: boolean
  loading: boolean
  hasIntegration: boolean
  isWAUser: boolean
  validPhone: boolean
  whatsappCurrentNumber: string
  qrCode: string
  chats: WhatsappChat[]
  chat: WhatsappChat | null
  messages: WhatsappMessage[] | []
  pagination: any | null
}

export const useWhatsappStore = defineStore('whatsapp', {
  state: (): WhatsappStore => ({
    disconnected: false,
    loading: true,
    hasIntegration: false,
    isWAUser: false,
    validPhone: true,
    whatsappCurrentNumber: '',
    qrCode: '',
    chats: [],
    chat: null,
    messages: [],
    pagination: null,
  }),
  actions: {
    setDisconnected(value: boolean = true) {
      this.disconnected = value
    },
    setLoading() {
      this.loading = false
    },
    setIntegration(value: boolean) {
      this.hasIntegration = value
    },
    setWAUser(value: boolean) {
      this.isWAUser = value
    },
    async setValidPhone(value: any) {
      this.validPhone = value.isTheSame
      this.whatsappCurrentNumber = value.whatsappCurrentNumber
      if (!value.isTheSame) {
        await triggerWALogout()
        setTimeout(() => {
          this.validPhone = true
        }, 500)
      }
    },
    setQrCode(value: string) {
      this.qrCode = value
    },
    async setChat(leadId: number) {
      const { data } = await useGetWALeadChat(leadId)
      if (!data.value) {
        this.chat = null
        this.messages = []
        return
      }
      this.chat = data.value.data
      await this.setMessages(leadId)
    },
    async setMessages(leadId: number, older_than_date?: string, more: boolean = false) {
      const { data, pagination } = await useGetWALeadMessages(leadId, older_than_date)
      if (!more) {
        this.messages = data.reverse()
      } else {
        this.messages = [...data.reverse(), ...this.messages]
      }

      this.pagination = pagination
    },
    setNewChat(value: WhatsappChat) {
      this.chat = value
    },
    setNewMessage(value: WhatsappMessage) {
      this.messages.push(value)
    },
    updateChat(value: { unreadCount: number; message: WhatsappMessage }) {
      if (this.chat) this.chat.unreadCount = value.unreadCount
      this.updateMessage(value.message)
    },
    updateMessage(message: WhatsappMessage) {
      const updatedMessageIndex = this.messages.findIndex((m: WhatsappMessage) => m.id === message.id)
      this.messages[updatedMessageIndex] = merge(this.messages[updatedMessageIndex], message)
    },
    updateUnreadCount(value: any) {
      if (!this.chat) return
      this.chat.unreadCount = value.unreadCount
    },
    setChats(value: any[]) {},
    deleteMessage(id: string) {
      const updatedMessageIndex = this.messages.findIndex((m: WhatsappMessage) => m.id === id)
      this.messages[updatedMessageIndex].deletedAt = new Date().toISOString()
    },
  },
  getters: {
    getDisconnected: (state) => state.disconnected,
    getLoading: (state) => state.loading,
    getHasIntegration: (state) => state.hasIntegration,
    getIsWAUser: (state) => state.isWAUser,
    getValidPhone: (state) => state.validPhone,
    getWhatsappCurrentNumber: (state) => state.whatsappCurrentNumber,
    getQrCode: (state) => state.qrCode,
    getChats: (state) => state.chats,
    getChat: (state) => state.chat,
    getMessages: (state) => state.messages,
    getPagination: (state) => state.pagination,
  },
})
