<template>
  <ol class="relative sm:ml-6">
    <transition-group name="slideY">
      <UiTimelineItem
        v-for="item in modelValue"
        :key="item.id"
        :model-value="item"
        :module
        :can-view-highlighted-timeline
        class="mb-1 border-black-10 sm:mb-8 sm:border-l"
        @update:model-value="$emit('update:modelValue', [...modelValue, ...$event])"
      />
    </transition-group>
  </ol>
</template>

<script setup lang="ts">
import type { TimelineItem } from '@/types'
import { PERMISSIONS } from '~/constants'

defineEmits(['update:modelValue'])

type Props = {
  modelValue: TimelineItem[]
  module?: 'lead' | 'deal'
}
withDefaults(defineProps<Props>(), {
  module: 'lead',
})

const canViewHighlightedTimeline = computed(() =>
  useHasPermissions([PERMISSIONS.VIEW_HIGHLIGHTED_NOTE_IN_TIMELINE], true)
)
</script>
