<template>
  <div class="grid h-full grid-rows-[auto_1fr] pt-3 xs:min-h-[393px] xs:pt-8 2xl:pt-12">
    <div>
      <div
        class="mb-2 flex flex-row items-center gap-2 px-3 xs:mb-2 xs:px-8 2xl:px-12"
        :class="{ 'xs:mb-6': !modelValue?.deals?.length }"
      >
        <UiFlagIcon :name="modelValue!.country?.iso2?.toLowerCase()" :size="isMobile ? 'sm' : 'lg'" />
        <h4 class="max-w-[360px] truncate [overflow-wrap:anywhere]">
          {{ modelValue!.name }}
        </h4>
      </div>
      <div v-if="modelValue?.deals?.length" class="mb-4 px-3 xs:px-8 2xl:px-12">
        <LeadInfoDealLink :deals="modelValue?.deals" />
      </div>

      <div id="tags-and-stage">
        <div
          v-if="filteredTags?.length || useGetStepTagPropertiesByCode(modelValue!.step?.code!)"
          class="mb-3 flex flex-row flex-wrap items-center gap-2 px-3 xs:mb-6 xs:px-8 2xl:px-12"
        >
          <PipelineLeadTag
            v-if="useGetStepTagPropertiesByCode(modelValue!.step?.code!)"
            :id="modelValue!.id"
            :properties="useGetStepTagPropertiesByCode(modelValue!.step?.code!)!"
            :tag-code="modelValue!.step?.code!"
            show-text
          />
          <PipelineLeadTag
            v-for="tag in filteredTags"
            :id="modelValue!.id"
            :key="tag.id"
            :properties="useGetTagPropertiesByCode(tag.code)!"
            :tag-code="tag.code"
            show-text
          />
        </div>
        <template v-if="isMobile">
          <LeadInfoStage
            v-model="modelValue!.pipeline_stage_id"
            :lead="modelValue!"
            :readonly="readonly"
            class="mb-4 px-3"
            is-mobile
            @stages:loaded="$emit('stages:loaded')"
          />
          <hr v-if="!isClosedLost" class="h-[1.5px] text-black-10" />
        </template>
      </div>
      <LeadActions
        v-if="isMobile && modelValue && !readonly"
        v-model="modelValue"
        class="relative z-10 rounded-b-3xl bg-white p-4"
        is-mobile
        @called="called"
        @reopen="emits('reopen')"
      />
      <div v-else class="flex flex-row items-center justify-between px-3 xs:px-8 2xl:px-12">
        <UiTabsSecondary v-model="tab" :tabs="tabs" />
      </div>
    </div>
    <transition v-if="!isMobile" name="fade" mode="out-in">
      <keep-alive>
        <component :is="currentComponent" v-model="modelValue" :readonly="readonly" @reopen="emits('reopen')" />
      </keep-alive>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { POPUPS } from '../dynamic/maps'
import type { Tab, Lead } from '@/types'
import { useAuthStore } from '@/store/auth'
import { TAGS } from '@/constants'
import { useUiStore } from '~/store/ui'

const route = useRoute()
const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue', 'stages:loaded', 'reopen'])

const modelValue = defineModel<Lead>()

type Props = {
  readonly?: boolean
}

defineProps<Props>()

const { smaller } = useBreakpoints(useGetBreakpoints())

const isMobile = smaller('xs')

const isClosedLost = inject<Ref<boolean>>('isClosedLost')

const goStage = useCookie('goStage')
const tab = ref('details')
const tabs: Tab[] = [
  {
    id: 'details',
    text: 'Details',
  },
  {
    id: 'source',
    text: 'Source',
  },
]

const tabComponentMap = {
  details: resolveComponent('LeadInfoDetails'),
  source: resolveComponent('LeadInfoSource'),
}

const currentComponent = computed(() => tabComponentMap[tab.value as keyof typeof tabComponentMap])

const authStore = useAuthStore()

const filteredTags = computed(() => {
  return modelValue.value!.tags?.filter(({ code }) => {
    if (authStore.getIsAgent && (code === TAGS.SHADOW_ORIGINAL_LEAD || code === TAGS.SHADOW_COPY_LEAD)) {
      return false
    }

    return true
  })
})

watch(
  () => goStage.value,
  (value) => {
    if (value && tab.value !== 'details') tab.value = 'details'
  }
)

onNuxtReady(() => {
  if (route.query['blur-stage'] === 'true') {
    uiStore.showPopup(
      POPUPS.LEAD_CALL_POST_STAGE_REMINDER,
      { stage: modelValue.value?.stage },
      {
        'update:modelValue': () => {
          navigateTo(`/leads/${modelValue.value?.id}`)
        },
      }
    )
  }
})

const called = ({ callId, fullOutcome, showStage }: { callId: string; fullOutcome: boolean; showStage: boolean }) => {
  navigateTo(`/leads/${modelValue.value?.id}/call/${callId}/?full-outcome=${fullOutcome}&blur-stage=${showStage}`)
}
</script>

<style scoped></style>
