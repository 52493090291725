<template>
  <div class="flex flex-row items-center">
    {{ getTextWithoutTag({ text: modelValue.additional[0].value, tag: 'span' }) }}
    <PipelineLeadTag
      :id="`shadow-lead-${shadowLeadId}`"
      class="ml-2 cursor-pointer"
      :properties="tag"
      :tag-code="TAGS.SHADOW_COPY_LEAD"
      show-text
      @click="navigateTo(`/leads/${shadowLeadId}`)"
    ></PipelineLeadTag>
  </div>
</template>
<script setup lang="ts">
import type { TimelineItem } from '@/types'
import { TAGS } from '@/constants'
type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()

const { extractValueFromTag, getTextWithoutTag } = useTagFormatter()

const isShadowLead = inject<Ref<boolean>>('isShadowLead')

const shadowLeadId = extractValueFromTag({ text: props.modelValue.additional[0].value, tag: 'span' })

const tag = computed(
  () => useGetTagPropertiesByCode(isShadowLead?.value ? TAGS.SHADOW_ORIGINAL_LEAD : TAGS.SHADOW_COPY_LEAD)!
)
</script>
