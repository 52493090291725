<template>
  <div class="rounded-[6px] bg-black-03 p-2">
    <div class="flex flex-row items-start gap-2">
      <UiInputCheckbox
        round
        name="checked"
        :model-value="checked"
        read-only
        class="mt-1"
        :border-color-class="checked ? '' : 'border-black-30'"
      />
      <div class="flex flex-col">
        <div class="text-caption text-black-90">
          <slot name="title" />
        </div>
        <div class="text-caption-4 mb-2 text-black-40">
          <slot name="date" />
        </div>
      </div>
    </div>
    <div class="text-caption-2 mb-[6px] text-black-80">
      <slot name="description" />
    </div>
    <span class="text-subhead-3 cursor-pointer text-primary-100" @click="emit('check')">Check the tour!</span>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (e: 'check'): void
}>()

type Props = {
  checked: boolean
}

defineProps<Props>()
</script>

<style scoped></style>
