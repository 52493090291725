<template>
  <div class="h-1 w-full rounded-full bg-black-10">
    <div class="h-1 rounded-full bg-primary-90" :style="{ width: `${progress}%` }"></div>
  </div>
</template>

<script setup lang="ts">
const progress = ref(0)
const interval = ref<NodeJS.Timeout>()


onMounted(() => {
  interval.value = setInterval(() => {
    if (progress.value === 100) {
      progress.value = 0
    } else {
      progress.value += 1
    }
  }, 5)
})

onUnmounted(() => {
  clearInterval(interval.value)
})
</script>

<style scoped></style>
