import { useAuthStore } from '~/store/auth'

export default (permissions: string[], skipAdminAndCeo = false) => {
  const authStore = useAuthStore()

  return (
    permissions.some((p) => authStore.getPermissions?.includes(p)) ||
    (!skipAdminAndCeo && (authStore.getIsAdmin || authStore.getIsCeo))
  )
}
