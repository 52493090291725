<template>
  <component :is="icon" v-if="icon" :id="name" :class="sizeMap[size]" />
  <div v-else :class="sizeMap[size]"></div>
</template>

<script setup lang="ts">
type Props = {
  name: string
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

const props = withDefaults(defineProps<Props>(), {
  size: 'sm',
})

const getIcon = () => defineAsyncComponent(() => import(`../../assets/icons/${props.name}.svg`))

const icon = shallowRef(getIcon())

watch(
  () => props.name,
  () => {
    icon.value = getIcon()
  }
)

const sizeMap = {
  xxs: 'h-4 w-4',
  xs: 'h-5 w-5',
  sm: 'h-6 w-6',
  md: 'h-8 w-8',
  lg: 'h-10 w-10',
  xl: 'h-16 w-16',
}
</script>

<style lang="scss"></style>
