<template>
  <div class="flex flex-col gap-2">
    <p>
      Lead data has been enriched or fixed through the application of the enrichment rule
      <NuxtLink :to="`/rotations/data-enrichment?query=${modelValue.data?.lead_enrichment_name}`">
        <b>"{{ modelValue.data?.lead_enrichment_name }}".</b>
      </NuxtLink>
    </p>
    <div class="flex flex-col gap-1">
      <span class="text-caption-2 text-black-40">Original values:</span>
      <div class="flex flex-row flex-wrap items-center gap-x-2 gap-y-1">
        <span v-for="(info, index) in originalItems" :key="info.field">
          <span>{{ info.field }}</span> =
          <span>{{
            (Number(info.value) === -1 ? 'Unknown' : info.value) + (originalItems.length - 1 > index ? ';' : '')
          }}</span>
        </span>
      </div>
    </div>
    <div class="flex flex-col gap-1">
      <span class="text-caption-2 text-black-40">Updated info:</span>
      <div class="flex flex-row flex-wrap items-center gap-x-2 gap-y-1">
        <span v-for="(info, index) in newItems" :key="info.field">
          <span>{{ info.field }}</span> =
          <span>{{
            (Number(info.value) === -1 ? 'Unknown' : info.value) + (newItems.length - 1 > index ? ';' : '')
          }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TimelineItem } from '~/types'

const modelValue = defineModel<TimelineItem>({ required: true })

const originalItems = modelValue.value.additional.old_data?.filter((item) => item.value)
const newItems = modelValue.value.additional.new_data?.filter((item) => item.value)
</script>

<style scoped></style>
