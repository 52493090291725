<template>
  <Teleport to="body">
    <transition name="fade">
      <component
        :is="uiStore.getDynamicSidePanel.component"
        v-model="uiStore.getDynamicSidePanel.value"
        v-bind="uiStore.getDynamicSidePanel.data"
        v-on="uiStore.getDynamicSidePanel.events"
        @update:model-value="uiStore.cleanSidePanel"
      />
    </transition>
  </Teleport>
</template>

<script setup lang="ts">
import { useUiStore } from '@/store/ui'

const uiStore = useUiStore()
</script>

<style scoped></style>
