<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
      <DynamicSidePanel />
      <DynamicMobilePanel />
      <DynamicPopup />
      <DynamicActionBar />
      <DynamicActionMenu />
      <template v-if="authStore.getIsLoggedIn">
        <LeadCallInbound />
        <SupportPanel />
        <DynamicTour />
      </template>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import * as Sentry from '@sentry/vue'
import { useIOStore } from '~/store/io'
import { useAuthStore } from '~/store/auth'
import { useWhatsappStore } from '~/store/whatsapp'
import type { User } from '@/types'

const ioStore = useIOStore()
const authStore = useAuthStore()
const whatsappStore = useWhatsappStore()

onNuxtReady(async () => {
  if (authStore.getIsLoggedIn) await initUser()
})

watch(
  () => authStore.getUser,
  (value: User) => {
    if (
      value &&
      whatsappStore.getHasIntegration &&
      `${value.country_phone_code}${value.phone}` !== whatsappStore.getWhatsappCurrentNumber
    ) {
      triggerWALogout()
    }
  }
)

watch(
  () => authStore.getIsLoggedIn,
  async (value: boolean) => {
    if (value) {
      await initUser()
    }
  }
)

const initUser = async () => {
  await Promise.all([useGetReleaseVersions(), useGetProfile(), useGetUnreadNotificationsCount()])
  Sentry.setUser({
    id: authStore.getUser.id!,
    username: authStore.getUser.name,
    email: authStore.getUser.email,
    role: authStore.getUser.role?.name,
  })
  ioStore.activateCall()
}

onMounted(() => {
  // Work around for https://github.com/nuxt/nuxt/issues/13350
  // @TODO Remove when fixed
  if (typeof window !== 'undefined') {
    window.addEventListener('error', (ev) => {
      const messages = [
        `Uncaught NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.`, // Chromium
        `NotFoundError: Node.insertBefore: Child to insert before is not a child of this node`, // Firefox
        `NotFoundError: The object can not be found here.`, // Safari
      ]
      if (messages.some((message) => ev.message.includes(message))) {
        ev.preventDefault()
        window.location.reload()
      }
    })
  }
})
</script>
