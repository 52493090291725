import type { LeadTagUiProperties } from '@/types'
import { STEPS } from '@/constants'

const TAGS_MAP = new Map<string, LeadTagUiProperties>([
  [
    STEPS.FRESH,
    {
      text: 'Fresh lead',
      icon: 'fresh-lead',
      tooltip: 'Fresh lead. Work with this lead was not started.',
      class: 'bg-additional-2-20 text-additional-2-120',
    },
  ],
  [
    STEPS.UNFROZEN,
    {
      text: 'Unfrozen lead',
      icon: 'unfrozen-lead',
      tooltip: 'This lead was unfreezed from “Future buyer”. Please review lead history in timeline.',
      class: 'bg-primary-10 text-primary-80',
    },
  ],
  [
    STEPS.HOT,
    {
      text: 'Hot lead',
      icon: 'hot-lead',
      tooltip:
        'Hot lead. Created as a result of merging two or more leads or creating multiple requests with the same contact information.',
      class: 'bg-additional-1-50 text-error-100',
    },
  ],
  [
    STEPS.FAILED,
    {
      text: 'Failed lead',
      icon: 'failed-lead',
      tooltip: 'Failed lead. The lead contains a failed phone number.',
      class: 'bg-additional-3-10 text-additional-3-100',
    },
  ],
])

export default (code: string): LeadTagUiProperties | undefined => TAGS_MAP.get(code)
