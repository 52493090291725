<template>
  <Teleport to="body">
    <transition name="fade">
      <component
        :is="uiStore.getDynamicTour.component"
        v-bind="uiStore.getDynamicTour.data"
        v-model="uiStore.getDynamicTour.value"
        v-on="uiStore.getDynamicTour.events"
        @update:model-value="uiStore.cleanTour"
      />
    </transition>
  </Teleport>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui'

const uiStore = useUiStore()
</script>

<style scoped></style>
