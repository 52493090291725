<template>
  <div class="relative flex h-screen w-full flex-col bg-white">
    <Header />
    <div v-if="currentError" class="flex h-full flex-col items-center justify-center">
      <div class="mb-8 flex items-center">
        <component :is="currentError.illustration" class="w-full max-w-[320px]" />
      </div>
      <div class="relative mb-4 select-none">
        <span
          :class="{ invisible: currentError.hideStatusCode }"
          class="text-[180px] font-bold leading-[0.8] tracking-wider text-primary-05 md:text-[230px]"
        >
          {{ error.statusCode }}
        </span>
        <div class="absolute inset-y-0 m-auto flex flex-col items-center justify-center gap-4 text-center">
          <h1 class="text-primary-80">{{ currentError.title }}</h1>
          <!-- eslint-disable-next-line -->
          <span class="text-xl text-black-100" v-html="currentError.description" />
          <UiButtonBase
            v-if="currentError.button"
            :id="currentError.button.id"
            class="relative z-10 min-h-10"
            size="big"
            @click="navigateTo(currentError.button.link)"
          >
            {{ currentError.button.text }}
          </UiButtonBase>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

type Props = {
  error: NuxtError
}
const props = defineProps<Props>()

type ErrorIllustration = {
  title: string
  description: string
  illustration: any
  hideStatusCode?: boolean
  button?: {
    id: string
    text?: string
    link?: string
  }
}

const statusCodeIllustrationsMap = new Map<number, ErrorIllustration>([
  [
    400,
    {
      title: 'Bad request',
      description: 'Oops! Looks like the request is invalid. Please check your request and try again.',
      illustration: await import('@/assets/illustrations/ghost.svg?component'),
    },
  ],
  [
    404,
    {
      title: 'Not found',
      description: "Oops! We can't find what you're looking for. Please check your request and try again.",
      illustration: await import('@/assets/illustrations/ghost.svg?component'),
    },
  ],
  [
    500,
    {
      title: 'Internal Server Error',
      description:
        "Oops! Something went wrong with the server. We're already working on it. Please try again in a little while.",
      illustration: await import('@/assets/illustrations/power-outlet.svg?component'),
    },
  ],
  [
    503,
    {
      title: 'Oops! Connection Issue',
      description:
        "It looks like there's a problem with the telephony system connectivity. Please, <b>try restarting your page</b> , or <b>contact the IT support</b> team if the issue persists.",
      illustration: await import('@/assets/illustrations/connection-error.svg?component'),
    },
  ],
  [
    401,
    {
      title: 'Restricted Access',
      description:
        'You are not authorized to Sign in / Sign up to this system. Only users from ThriveState domain can be authorized to the system.',
      illustration: await import('@/assets/illustrations/restricted-access.svg?component'),
      hideStatusCode: true,
      button: {
        id: 'back-to-main-page',
        text: 'Back to main page',
        link: '/',
      },
    },
  ],
])

console.log(props.error.message)

const currentError = computed(() => {
  const error = statusCodeIllustrationsMap.get(props.error.statusCode)
  return error || statusCodeIllustrationsMap.get(500)
})
</script>
