<template>
  <div>
    <b v-if="authorName">{{ authorName }}</b> released this lead to Lead Pool due to <b>reason:</b>
    <span class="ml-2 bg-secondary-20 px-1">{{ reason }}</span>
  </div>
</template>
<script setup lang="ts">
import type { TimelineItem } from '@/types'
import { useAuthStore } from '~/store/auth'

const authStore = useAuthStore()

type Props = {
  modelValue: TimelineItem
}

const props = defineProps<Props>()

const authorName = props.modelValue.user?.id === authStore.getUser.id ? 'You' : props.modelValue.user?.name

const reason = computed(() => {
  return props.modelValue.additional[0].value.split('reason: ')[1]
})
</script>
