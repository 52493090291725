<template>
  <div
    v-show="modelValue"
    ref="devicesElement"
    class="text-body-2 absolute -top-40 right-1 z-30 mx-auto inline-block w-56 rounded-lg bg-black-90 p-4 text-left font-medium text-white shadow-sm"
  >
    <p class="text-subhead-1 mb-4">Audio devices</p>
    <UiInputRadio id="devices" v-model="devices" :items="devicesItems" class="mb-4" vertical />
    <div
      id="volume-visualizer"
      ref="volumeVisualizer"
      class="relative h-[3px] w-full bg-black-70 before:absolute before:inset-y-0 before:left-0 before:bg-black-30 before:content-['']"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { useUiStore } from '@/store/ui'

const uiStore = useUiStore()

type Props = {
  modelValue: Boolean
}

const props = defineProps<Props>()

let volumeCallback: Function
let audioStream: MediaStream
let volumeInterval: number
const volumeVisualizer = ref()

const devices = ref('micro')
const devicesItems = [
  { text: 'Speakers', value: 'speaker' },
  { text: 'Microphone', value: 'micro' },
]

onNuxtReady(async () => {
  try {
    audioStream = await navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: true,
      },
    })
    const audioContext = new AudioContext()
    const audioSource = audioContext.createMediaStreamSource(audioStream)
    const analyser = audioContext.createAnalyser()
    analyser.fftSize = 512
    analyser.minDecibels = -127
    analyser.maxDecibels = 0
    analyser.smoothingTimeConstant = 0.4
    audioSource.connect(analyser)
    const volumes = new Uint8Array(analyser.frequencyBinCount)
    volumeCallback = () => {
      analyser.getByteFrequencyData(volumes)
      let volumeSum = 0
      for (const volume of volumes) volumeSum += volume
      const averageVolume = volumeSum / volumes.length
      volumeVisualizer.value.style.setProperty('--volume', (averageVolume * 100) / 127 + '%')
    }
  } catch (e) {
    uiStore.showSnackBanner('Failed to initialize volume visualizer', 'error')
  }
})

watch(
  () => props.modelValue,
  (value) => {
    if (value) {
      if (volumeCallback !== null && volumeInterval === null) volumeInterval = setInterval(volumeCallback, 100)
    } else if (volumeInterval !== null) {
      clearInterval(volumeInterval)
      volumeInterval = null
    }
  },
  { immediate: true }
)

onUnmounted(() => {
  clearInterval(volumeInterval)
  audioStream?.getTracks().forEach((track) => {
    track.stop()
  })
})
</script>

<style scoped>
#volume-visualizer {
  --volume: 0%;
}

#volume-visualizer::before {
  width: var(--volume);
  transition: width 100ms linear;
}
</style>
