import type { Lead } from '@/types'

type Tag = {
  text: string
  tooltip?: string
}

export default async (lead: Lead): Promise<Tag[]> => {
  const interests: Tag[] = []

  if (lead.ready_to_move === true || lead.ready_to_move === false) {
    interests.push(lead.ready_to_move ? { text: 'Ready to move' } : { text: 'Not ready to move' })
  }
  if (lead.budget_max) {
    if (lead.budget_max.toString() === '-1.0000' || lead.budget_max === -1) {
      interests.push({
        text: 'Budget: Unknown',
      })
    } else {
      const currencies = await useCurrencies()
      const currency = currencies.find((c) => c.id === lead.currency_id)

      const aedFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'AED',
        notation: 'compact',
        maximumFractionDigits: 0,
      })
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency?.code,
        notation: 'compact',
        maximumFractionDigits: 0,
      })

      const interest = {
        text: `Budget: ${aedFormatter.format(Number(lead.budget_max))}`,
        tooltip: `Budget: ${
          lead.local_budget_max
            ? formatter.format(Number(lead.local_budget_max))
            : aedFormatter.format(Number(lead.budget_max))
        }`,
      }

      interests.push(interest)
    }
  }
  if (lead.bedrooms.length) {
    interests.push(...lead.bedrooms.map((i) => ({ text: `${i.name}` })))
  }
  if (lead.bathrooms) {
    interests.push({
      text: `${lead.bathrooms} Bathrooms`,
    })
  }
  if (lead.interests.length) {
    interests.push(...lead.interests.map((i) => ({ text: i.name })))
  }
  if (lead.locations.length) {
    interests.push(...lead.locations.map((i) => ({ text: i.name })))
  }
  if (lead.types.length) {
    interests.push(...lead.types.map((i) => ({ text: i.name })))
  }
  if (lead.area_min) {
    if (lead.area_min === -1.0) {
      interests.push({
        text: 'Place area: Unknown',
      })
    } else {
      interests.push({
        text: `${lead.area_min} - ${lead.area_max} Sq.Ft`,
        tooltip: `${Math.round((lead.area_min as number) * 0.3048)} - ${Math.round(
          (lead.area_max as number) * 0.3048
        )} Sq.M`,
      })
    }
  }
  return interests
}
