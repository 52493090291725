<template>
  <div class="text-body">
    <b class="text-subhead-1">Partner</b> added a new note on the Partner Portal:
    <span class="whitespace-pre-wrap bg-additional-1-30 px-1 [overflow-wrap:anywhere]">{{ textToShow }}</span>
  </div>
</template>

<script setup lang="ts">
import type { TimelineItem } from '@/types'

type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()
const textToShow = ref(props.modelValue.comment)
</script>

<style scoped lang="scss"></style>
