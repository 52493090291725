import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "is-admin": () => import("/usr/src/app/middleware/is-admin.ts"),
  "is-auth-protected": () => import("/usr/src/app/middleware/is-auth-protected.ts"),
  "is-auth-route": () => import("/usr/src/app/middleware/is-auth-route.ts"),
  "is-manager": () => import("/usr/src/app/middleware/is-manager.ts")
}