<template>
  <div class="flex flex-row justify-between">
    <div class="flex flex-1 flex-col gap-2">
      <div class="flex flex-row items-center gap-1">
        <!-- eslint-disable-next-line -->
        <span class="contents" v-html="replacedMessage"> </span>
      </div>
      <div v-if="modelValue.body_data?.note">
        <div class="text-caption-2 text-black-40">Other notes:</div>
        {{ modelValue.body_data.note }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TimelineItem } from '@/types'

type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()

const replacePlaceholders = (str: string, obj: any) => str.replace(/{([^{}]*)}/g, (_, b: string) => obj[b] || '')

const replacedMessage = replacePlaceholders(props.modelValue.body_text, props.modelValue.body_data)
</script>

<style scoped></style>
