<template>
  <Teleport to="body">
    <transition name="fade">
      <UiMenuActions
        v-if="uiStore.getDynamicActionsMenu?.value"
        ref="actionsMenu"
        :items="uiStore.getDynamicActionsMenu?.actions!"
        :name="uiStore.getDynamicActionsMenu?.name!"
        :width="200"
        :top="uiStore.getDynamicActionsMenu.y"
        :left="uiStore.getDynamicActionsMenu.x"
        @action="$event.handler()"
      />
    </transition>
  </Teleport>
</template>

<script setup lang="ts">
import { useUiStore } from '@/store/ui'

const uiStore = useUiStore()

const actionsMenu = ref<HTMLElement | null>(null)

watch(
  () => uiStore.getDynamicActionsMenu?.value,
  (value) => {
    if (value) {
      nextTick(() => {
        actionsMenu.value?.open()
      })
    }
  },
  {
    deep: true,
  }
)
</script>

<style scoped></style>
