<template>
  <div class="flex flex-col gap-6">
    <div class="flex flex-row items-center gap-2">
      <UiTagSecondary>
        <template #content>
          <div class="text-subhead-3 flex flex-row items-center gap-1 text-error-100">
            <UiIcon name="alert-triangle-filled" size="xs"></UiIcon>
          </div>
        </template>
      </UiTagSecondary>
      <div v-if="mainItem">
        {{ mainItem.value }}
      </div>
    </div>

    <div class="flex flex-col gap-3">
      <div v-for="info in additionalItems" :key="info.field">
        <span class="text-caption-2 text-black-40">{{ info.field }}:</span>
        <div class="flex flex-wrap gap-x-2 gap-y-1">
          <span>{{ info.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TimelineItem } from '@/types'

type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()

const mainItem = props.modelValue.additional.find((item) => item.field === 'Duplicate')
const additionalItems = props.modelValue.additional.filter((item) => item.value && item.field !== 'Duplicate')
</script>

<style scoped></style>
