<template>
  <div class="first-letter:capitalize">
    {{ authorName || 'System' }} assigned lead
    <template v-if="prevUser">
      from <b>{{ prevUser }}</b>
    </template>
    <template v-if="newUser">
      to
      <b>{{ newUser }}</b
      >.
    </template>
    <p v-if="reason">
      Reason:<span class="ml-2 bg-secondary-20 px-1">{{ reason }}</span>
    </p>
  </div>
</template>
<script setup lang="ts">
import type { TimelineItem, User } from '@/types'
import { useAuthStore } from '~/store/auth'

const authStore = useAuthStore()
const users = ref<User[]>([])

type Props = {
  modelValue: TimelineItem
}

const props = defineProps<Props>()

const authorName = props.modelValue.user?.id === authStore.getUser.id ? 'You' : props.modelValue.user?.name

const prevUser = computed(() => {
  const user = props.modelValue.additional?.find((u) => u.field === 'Prev')
  return user ? getUserName(Number(user.value)) : ''
})

const newUser = computed(() => {
  const user = props.modelValue.additional?.find((u) => u.field === 'New')
  return user ? getUserName(Number(user.value)) : ''
})

const reason = computed(() => {
  return props.modelValue.additional?.find((u) => u.field === 'Reason')?.value
})

onNuxtReady(async () => (users.value = await useGetUsers()))

const getUserName = (id: number) => {
  return users.value.find((u) => u.id === id)?.name
}
</script>
