<template>
  <span :id="name" class="fi shrink-0" :class="[`fi-${name}`, sizeMap[size]]" />
</template>

<script setup lang="ts">
import 'flag-icons/css/flag-icons.min.css'
type Props = {
  name: string
  size?: 'sm' | 'lg'
}

withDefaults(defineProps<Props>(), {
  size: 'sm',
})

const sizeMap = {
  sm: '!h-4 !w-6',
  lg: '!h-6 !w-9',
}
</script>

<style lang="scss"></style>
