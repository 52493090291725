<template>
  <div>
    <UiTimelineItemLeadTransitionDefault v-if="!reason" :model-value="modelValue" />
    <div v-else class="flex flex-row flex-wrap items-center gap-2">
      <div>
        {{ authorName }} <span class="text-subhead-1">reopened</span> this lead due to reason
        <span class="text-subhead-1 bg-additional-1-30 px-1">{{ reason }}</span>
      </div>
      <div class="flex flex-row flex-wrap items-center gap-2">
        and it was moved to
        <div
          class="flex h-8 w-fit flex-row items-center justify-between gap-1 rounded-lg bg-primary-10 px-3 py-4 text-primary-100"
        >
          <UiIcon name="operations-forward" size="xs" />
          <h5 class="text-subhead-3">Interested</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TimelineItem } from '@/types'
import { useAuthStore } from '~/store/auth'

const authStore = useAuthStore()
type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()

const authorName = props.modelValue.user
  ? props.modelValue.user.id === authStore.getUser.id
    ? 'You'
    : props.modelValue.user.name
  : ''
const reason = props.modelValue.additional?.find((i) => i.field === 'Reopened this lead due to reason')?.value
</script>

<style scoped></style>
