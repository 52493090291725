<template>
  <div class="w-full">
    <div class="flex w-full flex-row items-center gap-2 p-2 pb-1" :class="{ 'shadow-down': showOverflowShadow }">
      <UiIcon
        name="chevron-big-left"
        class="!size-5 cursor-pointer select-none rounded-[6px] text-black-100 transition-all duration-300 hover:bg-black-03"
        @click="emit('back')"
      />
      <p class="text-subhead-4 text-black-90"><slot name="title" /></p>
    </div>
    <div ref="sectionRef" class="styled-scrollbar mx-2 mt-2 max-h-[153px] overflow-y-auto" @scroll="checkScroll">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (e: 'back'): void
}>()

const sectionRef = ref<HTMLElement>()
const showOverflowShadow = ref(false)

const checkScroll = () => {
  showOverflowShadow.value = Number(sectionRef.value?.scrollTop) > 0
}
</script>

<style scoped></style>
