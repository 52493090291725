<template>
  <div class="flex flex-col gap-2">
    <template v-if="customComponent">
      <component :is="customComponent" :model-value="modelValue">
        <template #default>
          <UiTimelineItemLeadTransitionDefault :model-value="modelValue" />
        </template>
        <template #notes>
          <div v-if="notes">
            <div class="text-caption-2 text-black-40">Other notes:</div>
            <div>{{ notes }}</div>
          </div>
        </template>
      </component>
    </template>
    <div v-else>
      <UiTimelineItemLeadTransitionDefault :model-value="modelValue" />
      <div v-if="notes">
        <div class="text-caption-2 text-black-40">Other notes:</div>
        <div>{{ notes }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Component } from 'nuxt/schema'
import type { TimelineItem } from '@/types'

type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()

const customFieldsMap = new Map<string, Component>([
  ['Lost', resolveComponent('UiTimelineItemLeadTransitionLost') as Component],
  ['Closed', resolveComponent('UiTimelineItemLeadTransitionClosed') as Component],
  ['Future Buyer', resolveComponent('UiTimelineItemLeadTransitionFutureBuyer') as Component],
  ['Interested', resolveComponent('UiTimelineItemLeadTransitionInterested') as Component],
])

// Transition item with custom details
const customComponent = computed(() => {
  const customFieldKey = Array.from(customFieldsMap.keys()).find((k) =>
    props.modelValue.additional.find((i) => i.field === 'Pipeline stage' && i.value === k)
  )
  if (!customFieldKey) return null
  return customFieldsMap.get(customFieldKey)
})

const notes = props.modelValue.additional?.find((i) => i.field === 'Lost reason')?.value
</script>

<style scoped></style>
