<template>
  <div :id="name" :ref="(el) => (refs[name].value = el as HTMLInputElement)">
    <slot name="activator" :is-open="showMenu" :on-click="toggle" />
    <Teleport to="body">
      <transition name="fade">
        <div
          v-show="showMenu"
          :id="`${name}_actions`"
          :class="menuClasses"
          class="fixed z-20 min-w-[180px] rounded-xl border-[1.5px] border-solid border-primary-10 bg-white p-1"
        >
          <ol>
            <div class="max-h-[250px] overflow-y-auto">
              <div
                v-for="(item, index) in items"
                :id="`action_${index}`"
                :key="index"
                :class="item.disabled ? 'text-black-40' : 'hover:bg-primary-05 hover:text-primary-120'"
                class="text-body-2 flex cursor-pointer select-none flex-row items-center gap-2 whitespace-nowrap p-3"
                @click="select(item)"
              >
                <div v-if="item.icon" class="w-6">
                  <UiIcon :name="item.icon" />
                </div>
                {{ item.text }}
              </div>
            </div>
          </ol>
        </div>
      </transition>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import type { InputItem } from '@/types'
import { useUiStore } from '~/store/ui'

const emits = defineEmits(['closed', 'action'])

type Props = {
  items: InputItem[]
  name: string
  menuClasses?: string
  alignRight?: boolean
  width?: number
  top?: number
  left?: number
}
const props = withDefaults(defineProps<Props>(), {
  width: 0,
  menuClasses: '',
  top: undefined,
  left: undefined,
})

const showMenu = ref<boolean>(false)

const refs = {
  [props.name]: ref<HTMLInputElement>(),
}

const uiStore = useUiStore()

onMounted(() => {
  onClickOutside(refs[props.name], () => {
    if (showMenu.value && !uiStore.getDynamicTour.value) showMenu.value = false
    emits('closed')
  })
})

const toggle = () => {
  showMenu.value = !showMenu.value
  if (!showMenu.value) return
  const viewportOffset = refs[props.name].value.getBoundingClientRect()
  // Here we get the element position to the viewport
  const top = props.top || viewportOffset.top
  const left = props.left || viewportOffset.left

  //   Then we set the position of the menu to those coordinates (if theres space)
  //   We do this because we use Teleport to transport to the body of the page
  //   Because otherwise, the menu would be clipped by tables, forms, etc
  //   Source: Trust me
  setTimeout(() => {
    const menu = document.getElementById(`${props.name}_actions`)

    if (menu) {
      const spaceToBottom = window.innerHeight - top

      if (spaceToBottom < menu.offsetHeight + Number(refs[props.name].value?.offsetHeight)) {
        menu.style.top = `${top - menu.offsetHeight}px`
      } else {
        menu.style.top = `${top + Number(refs[props.name].value?.offsetHeight)}px`
      }

      const spaceToRight = window.innerWidth - left

      const width = Number(
        props.width || (refs[props.name].value?.offsetWidth > 180 ? refs[props.name].value?.offsetWidth : 180)
      )

      if (spaceToRight < width || props.alignRight) {
        menu.style.left = `${left - width + Number(refs[props.name].value?.offsetWidth)}px`
      } else {
        menu.style.left = `${left}px`
      }
      menu.style.width = `${width}px`
    }
  })
}

const select = (item: InputItem) => {
  if (item.disabled) return
  emits('action', item.value)
  showMenu.value = false
}

defineSlots<{
  activator(props: { isOpen: boolean; onClick: typeof toggle }): void
}>()

defineExpose({
  open: () => {
    toggle()
  },
  close: () => {
    showMenu.value = false
    emits('closed')
  },
})
</script>

<style scoped></style>
