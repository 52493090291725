<template>
  <div
    :id="`search-card-${item.id}`"
    :class="{ '!items-start': showDetails }"
    class="group relative flex cursor-pointer items-center gap-4 bg-transparent px-6 py-1.5 hover:bg-black-03"
    @click.stop="openPage()"
  >
    <p class="rounded-full bg-primary-05 p-1.5">
      <UiIcon v-if="isLead" name="filter" class="!size-5 text-primary-100" />
      <UiIcon v-else name="file-text-1" class="!size-5 text-primary-100" />
    </p>
    <div>
      <p class="text-subhead-4 group-active:text-primary-100">{{ item.name || item.contacts[0].name }}</p>
      <template v-if="showDetails">
        <p class="text-caption mt-1.5 text-black-60">
          <span>Updated {{ format(new Date(item.updated_at), 'MMM dd, yyyy') }}</span> |
          <span>{{ formattedLabelAddress }}</span>
        </p>
        <p v-if="hasNote" class="text-caption mt-1">
          Note: <span class="text-black-60">{{ hasNote }}</span>
        </p>
      </template>
    </div>
    <div
      v-if="showDetails"
      class="absolute right-6 top-1.5 flex items-center gap-1.5 opacity-0 group-hover:opacity-100 group-[.driver-active-element]:opacity-100"
    >
      <UiButtonGhost :id="`copy-link-${item.id}`" class="!w-6 !text-black-70 hover:!text-primary-100"
        ><UiIcon name="link-normal" class="!min-w-6" @click.stop="copyLink()"
      /></UiButtonGhost>
      <UiButtonGhost :id="`new-tab-${item.id}`" class="w-6 !text-black-70 hover:!text-primary-100"
        ><UiIcon name="new-tab" class="!min-w-6" @click.stop="openInNewTab()"
      /></UiButtonGhost>
    </div>
  </div>
</template>

<script setup lang="ts">
import { format } from 'date-fns'
import upperFirst from 'lodash/upperFirst'
import { PIPELINES } from '~/constants'
import type { GlobalSearchItem } from '@/types'

const emits = defineEmits(['close'])

type Props = {
  item: GlobalSearchItem
  showDetails?: boolean
  search?: string
}

const props = defineProps<Props>()

const hasNote = computed(() => {
  if (props.item.activities?.length) {
    const foundActivity = props.item.activities.find((activity) =>
      activity.comment.toLowerCase().includes(props.search!.toLowerCase())
    )
    if (foundActivity) {
      const words = foundActivity.comment.split(' ')
      const indexOfSearchTerm = words.findIndex((word: string) => word.includes(props.search!))
      const startIndex = Math.max(indexOfSearchTerm - 2, 0)
      const endIndex = Math.min(indexOfSearchTerm + 4, words.length - 1)
      const context = words.slice(startIndex, endIndex + 1).join(' ')
      return `...${context}...`
    } else {
      return ''
    }
  }
  return ''
})

const isLead = computed(() => {
  return props.item.index === 'leads'
})

const configEnv = useRuntimeConfig()

const domain = configEnv.public.APP_URL

const formattedLabelAddress = computed(() => {
  if (isLead.value) {
    return `Leads/${
      props.item.pipelines.code === PIPELINES.SALES ? 'Pipeline' : upperFirst(props.item.pipelines.code)
    }/${props.item.name}`
  } else {
    return `Deals${props.item.pipelines.code === PIPELINES.ARCHIVE ? '/Archive/' : '/'}${
      props.item.name || props.item.contacts[0].name
    }`
  }
})

const formattedUrlAddress = computed(() => {
  if (isLead.value) {
    return `/leads/${props.item.id}${hasNote.value ? '#notes' : ''}`
  } else {
    return `/deals/${props.item.id}`
  }
})

const copyLink = () => {
  const path = formattedUrlAddress.value.substring(1)
  navigator.clipboard.writeText(domain + path)
}

const setRecentlySearched = async () => {
  if (props.showDetails) {
    const { id, name, index } = props.item
    const payload = {
      id,
      index,
      name: name || props.item.contacts[0].name,
    }
    await usePostRecentlyGlobalSearch(payload)
  }
}

const openPage = () => {
  setRecentlySearched()
  navigateTo(formattedUrlAddress.value)
  emits('close')
}

const openInNewTab = () => {
  setRecentlySearched()
  navigateTo(formattedUrlAddress.value, {
    open: {
      target: '_blank',
    },
  })
}
</script>

<style scoped></style>
