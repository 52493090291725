<template>
  <ul
    class="flex flex-wrap items-start justify-start transition-opacity duration-200"
    :class="{ 'opacity-50': disabled }"
  >
    <li
      v-for="(tab, index) in tabs"
      :id="tab.id"
      :key="tab.id"
      class="relative h-[38px] rounded-none border-none py-2 outline-none after:transition-all after:duration-200"
      :tabindex="tab.disabled ? '-1' : 0"
      :class="[
        {
          'after:absolute after:inset-x-0 after:bottom-0 after:z-10 after:mx-auto after:h-[2px] after:w-full after:rounded-full after:bg-primary-100 after:content-[\'\']':
            tab.id === modelValue,
          'after:absolute after:inset-x-0 after:bottom-0 after:z-10 after:mx-auto after:h-[2px] after:w-full after:rounded-full after:bg-primary-50 after:opacity-0 after:content-[\'\'] hover:after:opacity-100':
            tab.id !== modelValue && !tab.disabled && !disabled,
        },
        tab.disabled || disabled
          ? 'cursor-default text-black-40'
          : 'cursor-pointer rounded-none outline-none ring-success-100 focus-visible:border-none focus-visible:ring-2 focus-visible:ring-inset',
        alignLeft ? 'pr-4' : 'px-4',
        { 'text-error-100': tab.error },
      ]"
      @click="selectTab(tab)"
    >
      <h5 v-if="tab.id === modelValue" :class="bigFont ? 'text-subhead-1' : 'text-subhead-3'">
        {{ `${numberedTabs ? `${index + 1}. ` : ''}${tab.text}` }}
      </h5>
      <small v-else :class="{ '!text-base': bigFont }">
        {{ `${numberedTabs ? `${index + 1}. ` : ''}${tab.text}` }}
      </small>
    </li>
  </ul>
</template>

<script setup lang="ts">
import type { Tab } from '@/types'

const router = useRouter()
const route = useRoute()

const emits = defineEmits(['update:modelValue'])

type Props = {
  modelValue: string | undefined
  tabs: Tab[]
  alignLeft?: boolean
  bigFont?: boolean
  useRoute?: boolean
  numberedTabs?: boolean
  disabled?: boolean
}

const props = defineProps<Props>()

if (props.useRoute) {
  watch(
    () => route.hash,
    () => {
      emits('update:modelValue', route.hash.split('#')[1])
    }
  )
}

onNuxtReady(() => {
  if (props.useRoute) {
    if (route.hash) {
      emits('update:modelValue', route.hash.split('#')[1])
    } else {
      emits('update:modelValue', props.tabs[0].id)
    }
  }
})

const selectTab = (tab: Tab) => {
  if (!tab.disabled && !props.disabled) {
    emits('update:modelValue', tab.id)
    if (props.useRoute) router.replace({ hash: `#${tab.id}` })
  }
}
</script>

<style scoped></style>
