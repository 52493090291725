<template>
  <div v-if="stagedMoved" class="flex flex-row flex-wrap items-center gap-2">
    <div>{{ authorName }} <span class="text-subhead-1" :class="{ capitalize: !authorName }">moved</span> this lead to</div>
    <div
      class="flex h-8 w-fit flex-row items-center justify-between gap-1 rounded-lg bg-primary-10 px-3 py-4 text-primary-100"
    >
      <UiIcon name="operations-forward" size="xs" />
      <h5 class="text-subhead-3">
        {{ stagedMoved }}
      </h5>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TimelineItem } from '@/types'
import { useAuthStore } from '~/store/auth'

const authStore = useAuthStore()
type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()

const authorName = props.modelValue.user
  ? props.modelValue.user.id === authStore.getUser.id
    ? 'You'
    : props.modelValue.user.name
  : ''
const stagedMoved = props.modelValue.additional?.find((i) => i.field === 'Pipeline stage')?.value
</script>

<style scoped></style>
