import Pusher from 'pusher-js'
import { useAuthStore } from '@/store/auth'
import { useUiStore } from '@/store/ui'
import { useIOStore } from '~/store/io'
import { PERMISSIONS } from '@/constants'

declare global {
  interface Window {
    pusherInstance: any
  }
}

export const useConnectToWebsocket = () => {
  const config = useRuntimeConfig()
  const authStore = useAuthStore()
  if (!process.client || window.pusherInstance) return

  const pusher = new Pusher(config.public.PUSHER_APP_KEY, {
    cluster: config.public.PUSHER_APP_CLUSTER,
    channelAuthorization: {
      transport: 'ajax',
      endpoint: `${config.public.API_URL}broadcasting/auth`,
      headers: {
        Authorization: 'Bearer ' + authStore.token,
      },
    },
  })
  //  We add to window so we can add other listeners in other parts
  window.pusherInstance = pusher
  subscribeToOnlineChannel(authStore.getUser?.id as number)
  subscribeToNotificationsChannel(authStore.getUser?.id as number)
  if (useHasPermissions([PERMISSIONS.MANAGE_QUEUE])) subscribeToLeadQueueChannel(authStore.getUser?.id as number)
}

const subscribeToOnlineChannel = (userId: number) => {
  const authStore = useAuthStore()
  const channelName = `presence-users.online.${userId}`
  const channel = window.pusherInstance.subscribe(channelName)
  channel.bind('user.status-changed', (e: any) => {
    authStore.setUserStatus(e.status)
  })
  channel.bind('user.logout', () => {
    useDisconnectFromWebsocket()
    window.location.reload()
  })
}

const subscribeToNotificationsChannel = (userId: number) => {
  const uiStore = useUiStore()
  const channelName = `private-users.notifications.${userId}`
  const channel = window.pusherInstance.subscribe(channelName)
  channel.bind('users.notifications', () => {
    uiStore.setHasNewNotifications(true)
  })
}

export const subscribeToIpCallChannel = (userId: number) => {
  const ioStore = useIOStore()
  const channelName = `private-users.calls_status.${userId}`
  const channel = window.pusherInstance.subscribe(channelName)
  channel.bind('users.calls_status', (e: any) => {
    ioStore.setHasActivatedPhoneCall(e)
  })
}

export const unSubscribeToIpCallChannel = (userId: number) => {
  const ioStore = useIOStore()
  const channelName = `private-users.calls_status.${userId}`
  window.pusherInstance.unsubscribe(channelName)
  ioStore.setHasActivatedPhoneCall(null)
}

export const subscribeToLeadQueueChannel = (userId: number) => {
  const uiStore = useUiStore()
  const channelName = `private-users.lead_queue.${userId}`
  const channel = window.pusherInstance.subscribe(channelName)
  channel.bind('users.lead_created', () => {
    uiStore.setNeedUpdateQueue(true)
  })
  channel.bind('users.lead_picked', () => {
    uiStore.setNeedUpdateQueue(true)
  })
  channel.bind('users.lead_moved_to_rotation', () => {
    uiStore.setNeedUpdateQueue(true)
  })
  channel.bind('users.lead_returned_to_queue', () => {
    uiStore.setNeedUpdateQueue(true)
    uiStore.setLeadMovedToQueue(true)
  })
  channel.bind('users.lead_queue_count', (event: any) => {
    uiStore.setCountLeadInQueue(event.lead_queue_count)
  })
}

export const useDisconnectFromWebsocket = () => {
  window.pusherInstance?.disconnect()
  window.whatsappInstance?.disconnect()
  window.whatsappInstance = null
}
