export const useGetWALeadMessages = async (id: number, older_than_date?: string) => {
  const { data, error } = await useAPIFetch(() => `/wa/leads/${id}/messages`, { params: { limit : 20, page: 1, older_than_date } })
  if (error.value) throw createError(error.value)
  return data.value as any
}

export const useGetWALeadChat = async (id: number) => {
  const data = await useAPIFetch(() => `/wa/leads/${id}/chat`)
  return data as any
}
