<template>
  <Teleport to="body">
    <transition name="fade">
      <component
        :is="uiStore.getDynamicPopup.component"
        v-bind="uiStore.getDynamicPopup.data"
        v-model="uiStore.getDynamicPopup.value"
        v-on="uiStore.getDynamicPopup.events"
        @update:model-value="uiStore.cleanPopup"
      />
    </transition>
  </Teleport>
</template>

<script setup lang="ts">
import { useUiStore } from '@/store/ui'

const uiStore = useUiStore()
</script>

<style scoped></style>
